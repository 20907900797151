import React from 'react';
import Branding from '../blocks/branding';
import Button from '../blocks/button';
import ContentWithoutDefaults from '../blocks/content-without-defaults';
import Head from '../blocks/head';
import Information from '../blocks/information';

const WithoutDefaultsPreset = (props) => (
  <>
    <Head />

    <Branding {...props} />

    <Button {...props} />

    <ContentWithoutDefaults {...props} />

    <Information {...props} />
  </>
);

export default WithoutDefaultsPreset;
