import { Flex, styled } from '@kandji-inc/nectar-ui';
import { CVEImpactingTheMostDevicesTile } from './tiles/cve-impacting-the-most-devices-tile';
import { NewVulnerabilitiesDetectedTile } from './tiles/new-vulnerabilities-detected-tile';
import { TopApplicationsWithVulnerabilitiesTile } from './tiles/top-applications-with-vulnerabilities-tile';
import { VulnerabilitiesByApplicationTile } from './tiles/vulnerabilities-by-application-tile';
import { VulnerabilitiesBySeverityTile } from './tiles/vulnerabilities-by-severity-tile';

const Container = styled(Flex, {
  maxHeight: '350px',
  overflow: 'hidden',
  transition: 'max-height 0.5s',

  '&.hidden': {
    maxHeight: 0,
    margin: 0,
  },
});

type DashboardProps = {
  isOpen: boolean;
};

const Dashboard = (props: DashboardProps) => {
  return (
    <Container
      pl5
      pr5
      gap="md"
      flow="column"
      className={props.isOpen ? '' : 'hidden'}
    >
      <Flex flow="row" gap="md" css={{ minHeight: '118px' }}>
        <NewVulnerabilitiesDetectedTile />
        <CVEImpactingTheMostDevicesTile />
      </Flex>
      <Flex flow="row" gap="md" css={{ minHeight: '206px' }}>
        <VulnerabilitiesBySeverityTile />
        <VulnerabilitiesByApplicationTile />
        <TopApplicationsWithVulnerabilitiesTile />
      </Flex>
    </Container>
  );
};

export { Dashboard };
