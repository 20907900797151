/* istanbul ignore file */
import {
  Button,
  ButtonGroup,
  Dialog,
  Flex,
  Icon,
  Label,
  SelectGroupedItems,
  SelectV2,
  SelectV2Primitives,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import { getCodeList } from 'country-list';
import deepcopy from 'deepcopy';
import { useState } from 'react';
import { i18n } from 'src/i18n';
import styled from 'styled-components';

const defaultModel = {
  zipcode: '',
  country: '',
  address: '',
  macOsDeviceCount: '',
  iosAppleTvDeviceCount: '',
  isEdr: null,
};

const Validation = ({ text }) => {
  if (!text) return null;
  return (
    <Text size="1" variant="danger" css={{ marginTop: '$1' }}>
      {text}
    </Text>
  );
};

const StyledContent = styled(SelectV2Primitives.Content)`
  width: var(--radix-select-trigger-width);
  margin: 4px 0;
  max-height: 320px;
  z-index: 9999;
  overflow-y: auto;
`;

const CountrySelect = ({ model, onChange, validation }) => {
  const countriesMap = [
    { value: i18n.t('US'), label: i18n.t('United States of America') },
    ...Object.entries(getCodeList()).map(([key, value]) => ({
      value: i18n.t(key),
      label: i18n.t(value as string),
    })),
  ];

  return (
    <Flex
      flow="column"
      gap="sm"
      css={{ maxHeight: '200px', overflowY: 'auto', flex: 1 }}
    >
      <Label above>{i18n.t('Country')}</Label>
      <SelectV2.Default
        label={i18n.t('Country')}
        options={countriesMap}
        value={model.country}
        aria-label="country"
        data-testid="country"
        onValueChange={(value) => onChange('country', value)}
        triggerProps={{
          variant: 'input',
          placeholder: i18n.t('Select country'),
        }}
        content={() => (
          <StyledContent position="popper">
            <SelectV2Primitives.Viewport>
              <SelectGroupedItems options={countriesMap} />
            </SelectV2Primitives.Viewport>
          </StyledContent>
        )}
      />
      <Validation text={validation} />
    </Flex>
  );
};

const DialogContent = ({ model, onChange, validation, onSubmit }) => {
  return (
    <Flex flow="column" gap="xl" css={{ padding: '0 60px' }}>
      <Flex flow="column" gap="md">
        <Text variant="secondary" css={{ fontWeight: '$medium' }}>
          {i18n.t('How many Apple devices will you manage?')}
        </Text>
        <Flex flow="row" gap="xl">
          <Flex
            flow="column"
            justifyContent="center"
            alignItems="center"
            gap="sm"
            css={{
              border: '1px solid $neutral20',
              borderRadius: '$1',
              padding: '$5 $4',
            }}
          >
            <Icon name="sf-desktop-computer" size={24} />
            <Label above css={{ fontWeight: '400' }}>
              {i18n.t('Mac')}
            </Label>
            <TextField
              placeholder={i18n.t('How many')}
              value={model.macOsDeviceCount}
              onChange={(e) => onChange('macOsDeviceCount', e.target.value)}
              type="number"
              data-testid="mac-device-count"
            />
          </Flex>
          <Flex
            flow="column"
            justifyContent="center"
            alignItems="center"
            gap="sm"
            css={{
              border: '1px solid $neutral20',
              borderRadius: '$1',
              padding: '$5 $4',
            }}
          >
            <Flex>
              <Icon name="sf-ipad-landscape" size={24} />
              <Icon name="sf-iphone" size={24} />
              <Icon name="sf-apple-tv" size={24} />
            </Flex>
            <Label above css={{ fontWeight: '400' }}>
              {i18n.t('Mobile or Apple TV')}
            </Label>
            <TextField
              placeholder={i18n.t('How many')}
              value={model.iosAppleTvDeviceCount}
              onChange={(e) =>
                onChange('iosAppleTvDeviceCount', e.target.value)
              }
              type="number"
              data-testid="other-device-count"
            />
          </Flex>
        </Flex>
        <Validation text={validation.deviceCount} />
      </Flex>
      <Flex flow="column" gap="md">
        <Text css={{ lineHeight: '1.5' }} variant="secondary">
          {i18n.t(
            'Are you interested in Endpoint Detection and Response to increase the security posture of your Mac fleet?',
          )}
        </Text>
        <ButtonGroup
          compact
          css={{ '& > button': { flex: 1 } }}
          variant="input"
          data-testid="edr-selector"
          buttons={[
            {
              label: i18n.t('Yes'),
              onClick: () => onChange('isEdr', true),
              selected: model.isEdr,
            },
            {
              label: i18n.t('No'),
              onClick: () => onChange('isEdr', false),
              selected: model.isEdr === false,
            },
          ]}
        />
      </Flex>
      <Flex flow="column" gap="sm">
        <Flex flow="column" gap="sm">
          <Label above>{i18n.t('Address')}</Label>
          <TextField
            placeholder={i18n.t('Enter your address')}
            value={model.address}
            onChange={(e) => onChange('address', e.target.value)}
            data-testid="address"
          />
          <Validation text={validation.address} />
        </Flex>
        <Flex flow="row" gap="xl">
          <CountrySelect
            model={model}
            onChange={onChange}
            validation={validation.country}
          />
          <Flex flow="column" gap="sm" css={{ flex: 1 }}>
            <Label above>{i18n.t('Zipcode')}</Label>
            <TextField
              placeholder={i18n.t('Enter your zipcode')}
              value={model.zipcode}
              onChange={(e) => onChange('zipcode', e.target.value)}
              data-testid="zipcode"
            />
            <Validation text={validation.zipcode} />
          </Flex>
        </Flex>
        <Validation text={validation.zipAndCountry} />
      </Flex>
      <Button
        variant="primary"
        onClick={onSubmit}
        data-testid="checkout-button"
      >
        {i18n.t('Checkout')}
      </Button>
    </Flex>
  );
};

const CheckoutDialog = ({ onClose, onToggleContactSales }) => {
  const initialValiation = {
    deviceCount: '',
    zipAndCountry: '',
    country: '',
    zipcode: '',
    address: '',
  };
  const [model, setModel] = useState(deepcopy(defaultModel));
  const [validation, setValidation] = useState(initialValiation);

  const handleChange = (key, value) => {
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const MIN_DEVICE_COUNT = 25;
  const sumOfTwoCounts =
    parseInt(model.macOsDeviceCount || '0', 10) +
    parseInt(model.iosAppleTvDeviceCount || '0', 10);

  const validate = () => {
    if (sumOfTwoCounts < MIN_DEVICE_COUNT) {
      setValidation((prev) => ({
        ...prev,
        deviceCount: i18n.t('Minimum of 25 devices required.'),
      }));
      return;
    }
    if (!model.address) {
      setValidation((prev) => ({
        ...prev,
        address: i18n.t('Please enter your address.'),
      }));
      return;
    }
    if (!model.zipcode && !model.country) {
      setValidation((prev) => ({
        ...prev,
        zipAndCountry: i18n.t('Please fill out zipcode and country.'),
      }));
      return;
    }
    if (!model.zipcode) {
      setValidation((prev) => ({
        ...prev,
        zipcode: i18n.t('Please enter your zipcode.'),
      }));
      return;
    }
    if (!model.country) {
      setValidation((prev) => ({
        ...prev,
        country: i18n.t('Please select your country.'),
      }));
      return;
    }
    return true;
  };

  const onSubmit = () => {
    setValidation(initialValiation);
    const isValid = validate();
    if (isValid) {
      // submit data
    }
  };

  const content = (
    <DialogContent
      model={model}
      onChange={handleChange}
      validation={validation}
      onSubmit={onSubmit}
    />
  );

  return (
    <Dialog
      css={{ width: '560px' }}
      headerCss={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
        paddingLeft: '28px',
      }}
      bodyCss={{ height: '100%', overflow: 'auto', paddingBottom: '40px' }}
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      title={i18n.t('A few more details before you checkout')}
      content={content}
    />
  );
};

export default CheckoutDialog;
