import { Flex, Table, styled } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';
import { memo } from 'react';
import { i18n } from 'src/i18n';
import type {
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
} from '../../../threat.types';
import ThreatListLoader from '../ThreatListLoader';
import ThreatListEmptyStates from './ThreatListEmptyStates';
import ThreatListTableHead from './ThreatListTableHead';
import ThreatListTablePaginationBar from './ThreatListTablePaginationBar';

const Container = styled(Flex, {
  height: '100%',
  position: 'relative',
  minHeight: 314,
  flexFlow: 'column',
  justifyContent: 'space-between',
});

const TableStyled = styled(Table, {
  '& th:last-child, td:last-child': {
    padding: 0,
    textAlign: 'right',
  },
  // overrides anchor resets from `_reboot.scss`
  ':where(th, td) a': {
    color: '$neutral90',
  },
});

const TableStickyContainer = styled(Flex, {
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.85)',
  flow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  maxHeight: '100%',
  position: 'sticky',
  top: 80,
});

const TablePlaceholder = styled(Flex, {
  height: 'calc(100% - 36px)',
  position: 'absolute',
  width: '100%',
  top: 36,
});

const PaginationContainer = styled(Flex, {
  paddingInline: '$5',
  backgroundColor: '$neutral0',
  boxShadow:
    '$shadows$inset_border_bottom_1 $colors$neutral0, $shadows$inset_border_top_1 $colors$neutral30',
  position: 'fixed',
  bottom: 0,
  alignItems: 'center',
  zIndex: 1,
  width: 'calc(100% - 256px)',
  '.full-width-sidebar-closed &': {
    width: 'calc(100% - 77px)',
  },
});

type ThreatListTableProps = Readonly<{
  dataCount: number | undefined;
  isLoading: boolean;
  isError: boolean;
  error: string | undefined;
  sort: SortColumnState;
  onColumnSort: (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => void;
  pagination: PaginationState;
  onPaginationChange: (page: number, pageSize: PageSize) => void;
  displayDetectionPeriod: string;
  displayDetectionSubject: string;
  isFiltering: boolean;
  showToast: ShowToast;
  columns: TableHeaderColumn[];
  isScrollable?: boolean;
  isSelectable?: boolean;
  children: React.ReactNode;
  onAllPageSelected?: (isSelected: boolean) => void;
  isAllPageSelected?: boolean;
  isPaginationPageSizeShown?: boolean;
  isMoreActionsShown?: boolean;
  bulkActions?: React.ReactNode;
  isLoadingForSelecting?: boolean;
  isFirstHeaderColumnShown?: boolean;
  paginationCss?: CSS;
  containerCss?: CSS;
  tablePlaceholderCss?: CSS;
}>;

const ThreatListTable = (props: ThreatListTableProps) => {
  const {
    dataCount,
    isLoading,
    isError,
    error,
    sort,
    onColumnSort,
    pagination,
    onPaginationChange,
    displayDetectionPeriod,
    displayDetectionSubject,
    isFiltering,
    columns,
    isScrollable = false,
    isSelectable = false,
    children,
    onAllPageSelected,
    isAllPageSelected = false,
    isPaginationPageSizeShown = true,
    isMoreActionsShown = true,
    bulkActions,
    isLoadingForSelecting = false,
    isFirstHeaderColumnShown = true,
    paginationCss,
    containerCss,
    tablePlaceholderCss,
  } = props;

  if (isError) {
    throw new Error(`Data fetching/validating error: ${error}`);
  }

  const isEmpty: boolean = dataCount === 0;
  const isEmptyOrLoading: boolean = isEmpty || isLoading;

  const handlePaginationChange = (newPage: number, newPageSize: PageSize) => {
    onPaginationChange(newPage, newPageSize);
  };

  return (
    <>
      <Container
        css={{
          overflow: isScrollable ? 'scroll' : 'unset',
          ...containerCss,
        }}
      >
        <TableStyled aria-label="Threats" data-testid="threats-table">
          <ThreatListTableHead
            sort={sort}
            onColumnSort={onColumnSort}
            isSortingDisabled={isEmptyOrLoading}
            columns={columns}
            isSelectable={isSelectable}
            isFirstHeaderColumnShown={isFirstHeaderColumnShown}
            isSelectionDisabled={
              isSelectable && (isEmptyOrLoading || isLoadingForSelecting)
            }
            onAllSelected={onAllPageSelected}
            isAllSelected={isAllPageSelected}
            isMoreActionsShown={isMoreActionsShown}
          />
          {children}
        </TableStyled>
        {isEmptyOrLoading && (
          <TablePlaceholder css={tablePlaceholderCss}>
            <TableStickyContainer>
              {isEmpty && !isLoading && (
                <ThreatListEmptyStates
                  isFiltering={isFiltering}
                  displayDetectionPeriod={displayDetectionPeriod}
                  displayDetectionSubject={displayDetectionSubject}
                />
              )}
              {isLoading && (
                <ThreatListLoader label={i18n.t('Loading Threats')} />
              )}
            </TableStickyContainer>
          </TablePlaceholder>
        )}
        <PaginationContainer py3 css={paginationCss}>
          <ThreatListTablePaginationBar
            dataCount={dataCount}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            bulkActions={bulkActions}
            isPageSizeShown={isPaginationPageSizeShown}
          />
        </PaginationContainer>
      </Container>
    </>
  );
};

export default memo(ThreatListTable);
