/* istanbul ignore file */
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import React, {
  useContext,
  useEffect,
  useState,
  type SetStateAction,
  type Dispatch,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import { i18n } from 'src/i18n';
import useWhiteBg from '../util/hooks/use-white-bg';
import CheckoutDialog from './CheckoutDialog';
import ContactSalesDialog from './ContactSalesDialog';
import QuoteDialog from './QuoteDialog';
import TrialResources from './Resources';
import TrialEnded from './TrialEnded';
import Walkthrough from './Walkthrough';
import ClosedMacs from './assets/closed-macs.png';
import HandWave from './assets/hand-wave.svg';
import TrialBannerMac from './assets/trial-banner-mac.png';
import {
  convertToCurrencyFormat,
  daysLeftInTrial,
  getTotalTrialDays,
} from './common.data';
import { OnboardingDialog } from './onboarding';
import { useDismissOnboardingDialog } from './useDismissOnboardingDialog';
import { type Quote, useGetQuote } from './useGetQuote';
import { useIsFirstTrialUserSession } from './useIsFirstTrialUserSession';

const TrialGrid = styled(Box, {
  display: 'grid',
  gridTemplateColumns: '1fr 0.5fr',
  gridTemplateRows: 'auto 1fr',
  gridGap: '24px',
  height: '100%',
});

const Header = styled(Flex, {
  position: 'relative',
  flexDirection: 'column',
  gap: '12px',
  height: '115px',
  padding: '16px',
  borderRadius: '8px',
  background: 'linear-gradient(91deg, #0F1317 0.18%, #51667B 103.75%)',
});

const ProgressBar = styled(Box, {
  position: 'relative',
  height: '6px',
  maxWidth: '420px',
  borderRadius: '4px',
  background: '#EDEDED',

  '&:after': {
    content: '',
    position: 'absolute',
    left: '0',
    backgroundColor: '$yellow50',
    height: '6px',
    transition: 'all 0.2s ease-in-out',
    borderRadius: '4px',
  },
});

const QuoteExistsView = ({
  quote,
  toggleContactSales,
  toggleQuoteDialog,
}: {
  quote: Quote;
  toggleContactSales: Dispatch<
    SetStateAction<{ isOpen: boolean; error: null }>
  >;
  toggleQuoteDialog: Dispatch<
    SetStateAction<{ isOpen: boolean; openOnDetails: boolean }>
  >;
}) => {
  return (
    <Flex flow="column">
      <Text css={{ fontWeight: 500, lineHeight: '20px' }}>
        {i18n.t('Your quote')}
      </Text>
      <Flex alignItems="center" gap="xs">
        <Heading size="3">
          {convertToCurrencyFormat(
            quote.quoteTotal,
            2,
            'en',
            quote.quoteTotalCurrency,
          )}
        </Heading>
      </Flex>
      <Text size="1">{i18n.t('for annual contract')}</Text>
      <Text
        size="1"
        variant="primary"
        css={{ cursor: 'pointer', fontWeight: 500, marginTop: '4px' }}
        onClick={() => toggleContactSales({ isOpen: true, error: null })}
        data-testid="contact-sales"
      >
        {i18n.t('Contact sales')}
      </Text>
      <Button
        compact
        css={{ position: 'absolute', top: '12px', right: '14px' }}
        data-testid="quote-details-button"
        onClick={() =>
          toggleQuoteDialog((prev) => ({
            ...prev,
            isOpen: true,
            openOnDetails: true,
          }))
        }
      >
        {i18n.t('Details')}
      </Button>
    </Flex>
  );
};

const GetAQuoteView = ({
  LDFF_quoteSyntax,
  toggleQuoteDialog,
  toggleContactSales,
}: {
  LDFF_quoteSyntax: string;
  toggleQuoteDialog: Dispatch<
    SetStateAction<{ isOpen: boolean; openOnDetails: boolean }>
  >;
  toggleContactSales: Dispatch<
    SetStateAction<{ isOpen: boolean; error: null }>
  >;
}) => {
  return (
    <>
      <Text>{i18n.t('Ready to learn more?')}</Text>
      <Button
        id="trial-dashboard-get-a-quote"
        data-testid="get-a-quote-button"
        onClick={() => toggleQuoteDialog((prev) => ({ ...prev, isOpen: true }))}
      >
        {i18n.t(LDFF_quoteSyntax)}
      </Button>
      <Flex alignItems="center" gap="xs">
        <Text>{i18n.t('or')} </Text>
        <Text
          variant="primary"
          css={{ cursor: 'pointer' }}
          data-testid="contact-sales"
          onClick={() => toggleContactSales({ isOpen: true, error: null })}
        >
          {i18n.t('contact sales')}
        </Text>
      </Flex>
    </>
  );
};

const CheckoutView = ({
  toggleCheckoutDialog,
  toggleContactSales,
}: {
  toggleCheckoutDialog: Dispatch<SetStateAction<{ isOpen: boolean }>>;
  toggleContactSales: Dispatch<
    SetStateAction<{ isOpen: boolean; error: null }>
  >;
}) => {
  return (
    <Flex flow="row" gap="md" alignItems="baseline">
      <Button
        onClick={() => toggleCheckoutDialog({ isOpen: true })}
        variant="primary"
        data-testid="upgrade-button"
      >
        {i18n.t('Upgrade')}
      </Button>
      <Flex alignItems="center" gap="xs">
        <Text>{i18n.t('or')} </Text>
        <Text
          variant="primary"
          css={{ cursor: 'pointer' }}
          onClick={() => toggleContactSales({ isOpen: true, error: null })}
          data-testid="contact-sales"
        >
          {i18n.t('contact sales')}
        </Text>
      </Flex>
    </Flex>
  );
};

const ContactSalesView = ({
  toggleContactSales,
}: {
  toggleContactSales: Dispatch<
    SetStateAction<{ isOpen: boolean; error: null }>
  >;
}) => {
  return (
    <>
      <Text size="1">{i18n.t('Ready to learn more?')}</Text>
      <Button
        onClick={() => toggleContactSales({ isOpen: true, error: null })}
        data-testid="contact-sales"
      >
        {i18n.t('Contact sales')}
      </Button>
    </>
  );
};

const TrialDashboard = () => {
  const isFirstTrialUserSession = useIsFirstTrialUserSession();
  const { isDismissed, dismissDialog } = useDismissOnboardingDialog();
  const [isOnboardingDialogOpen, setIsOnboardingDialogOpen] = useState(false);

  React.useEffect(() => {
    if (isFirstTrialUserSession && !isDismissed) {
      setIsOnboardingDialogOpen(true);
    }
  }, [isFirstTrialUserSession, isDismissed]);

  const history = useHistory();
  const {
    'exp-74-trial-dashboard-quoting': LDFF_quotingEnabled,
    'exp-012325-stripe-checkout': isStripeCheckoutOn,
    'dc-exp-quote-syntax': LDFF_quoteSyntax,
  } = useFlags(['exp-74-trial-dashboard-quoting']);
  const { currentCompany, userFirstName: firstName } =
    useContext(AccountContext);
  useWhiteBg();

  const TOTAL_TRIAL_DAYS = getTotalTrialDays(
    currentCompany?.created_at * 1000,
    +new Date(currentCompany.trial_banner_data?.trial_end_date),
  );
  const trialDaysLeft = daysLeftInTrial(
    currentCompany.trial_banner_data?.trial_end_date,
  );

  const { data: quoteData } = useGetQuote();
  const [isQuoteDialogOpen, toggleQuoteDialog] = useState({
    isOpen: false,
    openOnDetails: false,
  });
  const [isContactSalesDialogOpen, toggleContactSales] = useState({
    isOpen: false,
    error: null,
  });
  const [isCheckoutDialogOpen, toggleCheckoutDialog] = useState({
    isOpen: false,
  });

  const isLastDayOfTrial = trialDaysLeft === 1;
  const isTrialEnded = !trialDaysLeft;

  useEffect(() => {
    history.push('/trial');
  }, [history]);

  if (isTrialEnded) {
    return <TrialEnded />;
  }

  const isFromSignupFlow = Boolean(
    currentCompany.onboarding?.experience === 'v1',
  );
  const isQuotingEnabled = LDFF_quotingEnabled && isFromSignupFlow;

  const getCTAContent = () => {
    if (isQuotingEnabled && quoteData) {
      return (
        <QuoteExistsView
          quote={quoteData}
          toggleContactSales={toggleContactSales}
          toggleQuoteDialog={toggleQuoteDialog}
        />
      );
    }

    const companySize = currentCompany?.onboarding?.companySize;
    const isSmallCompany = companySize && parseInt(companySize as string) <= 25;

    if (isStripeCheckoutOn && isSmallCompany) {
      return (
        <CheckoutView
          toggleCheckoutDialog={toggleCheckoutDialog}
          toggleContactSales={toggleContactSales}
        />
      );
    }

    if (isQuotingEnabled) {
      return (
        <GetAQuoteView
          LDFF_quoteSyntax={LDFF_quoteSyntax}
          toggleQuoteDialog={toggleQuoteDialog}
          toggleContactSales={toggleContactSales}
        />
      );
    }

    return <ContactSalesView toggleContactSales={toggleContactSales} />;
  };

  return (
    <>
      <OnboardingDialog
        open={isOnboardingDialogOpen}
        onOpenChange={(open) => {
          setIsOnboardingDialogOpen(open);
          if (!open) {
            dismissDialog();
          }
        }}
      />
      <Box
        p5
        css={{
          height: 'calc(100vh - 48px)',
          minWidth: '1250px',
          marginLeft: '-49px',
          marginRight: '-48px',

          '@media (min-width: 1712px)': {
            maxWidth: '1760px',
            margin: 'auto',
          },
        }}
      >
        <TrialGrid>
          <Header
            css={{
              backgroundImage: `url(${TrialBannerMac}), linear-gradient(91deg, rgb(15, 19, 23) 0.18%, rgb(81, 102, 123) 103.75%)`,
              borderTopLeftRadius: '7px',
              borderBottomLeftRadius: '7px',

              backgroundPosition: 'right center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <Box css={{ maxWidth: '50%' }}>
              <Flex flow="column" gap="xs" mb3>
                <Flex gap="xs" alignItems="center">
                  <ReactSVG src={HandWave} alt="wave" />
                  <Text css={{ color: '$neutral0' }}>
                    {firstName
                      ? isLastDayOfTrial
                        ? i18n.t('Hi {firstName}, today is your', {
                            firstName,
                          })
                        : i18n.t('Hi {firstName},  you have', {
                            firstName,
                          })
                      : ''}

                    {!firstName
                      ? isLastDayOfTrial
                        ? i18n.t('Today is your')
                        : i18n.t('You have')
                      : ''}
                  </Text>
                </Flex>
                <Heading
                  size="3"
                  css={{
                    color: '$neutral0',
                    fontWeight: 500,
                    letterSpacing: '-0.8px',
                  }}
                >
                  {isLastDayOfTrial
                    ? i18n.t('Final day in trial')
                    : i18n.t('{daysLeft} days left in trial', {
                        daysLeft: trialDaysLeft,
                      })}
                </Heading>
              </Flex>
              <ProgressBar
                key={trialDaysLeft}
                css={{
                  '&::after': {
                    width: `calc((100% / ${TOTAL_TRIAL_DAYS}) * ${
                      TOTAL_TRIAL_DAYS - trialDaysLeft
                    } + 2%)`,
                  },
                }}
              />
            </Box>
          </Header>
          <Card
            css={{
              position: 'relative',
              display: 'flex',
              padding: 0,
              gap: '48px',
            }}
          >
            <Box
              css={{
                width: '120px',
                height: '100%',
                background:
                  'linear-gradient(278deg, #0F1317 48.5%, #51667B 99.15%)',
                borderTopLeftRadius: '7px',
                borderBottomLeftRadius: '7px',
              }}
            />
            <Flex
              hFull
              flow="column"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={ClosedMacs}
                alt="closed macs"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '157px',
                  height: '113px',
                  borderRadius: '7px',
                }}
              />
              <Flex
                flow="column"
                alignItems="center"
                justifyContent="center"
                gap="sm"
                css={{ alignSelf: 'flex-end' }}
              >
                {getCTAContent()}
              </Flex>
            </Flex>
          </Card>
          <Walkthrough />
          <TrialResources />
        </TrialGrid>
        {isCheckoutDialogOpen.isOpen && (
          <CheckoutDialog
            onClose={() => toggleCheckoutDialog({ isOpen: false })}
            onToggleContactSales={({ error }) => {
              toggleCheckoutDialog({ isOpen: false });
              toggleContactSales({ isOpen: true, error });
            }}
          />
        )}
        {isQuoteDialogOpen.isOpen && (
          <QuoteDialog
            openOnDetails={isQuoteDialogOpen.openOnDetails}
            details={quoteData}
            onClose={() =>
              toggleQuoteDialog((prev) => ({ ...prev, isOpen: false }))
            }
            onToggleContactSales={({ error }) => {
              toggleQuoteDialog((prev) => ({ ...prev, isOpen: false }));
              toggleContactSales({ isOpen: true, error });
            }}
          />
        )}
        <ContactSalesDialog
          pageName="Trial Dashboard"
          isOpen={isContactSalesDialogOpen.isOpen}
          setIsOpen={(isOpen) => toggleContactSales({ isOpen, error: null })}
          withError={isContactSalesDialogOpen.error}
        />
      </Box>
    </>
  );
};

export default TrialDashboard;
