import { blueprintLibraryCategories } from 'features/blueprints/common';
import AppBlocking from 'features/library-items/items/AppBlocking';
import Bookmarks from 'features/library-items/items/Bookmarks';
import CustomAppsV2 from 'features/library-items/items/custom-apps';
import CustomPrintersV2 from 'features/library-items/items/custom-printer';
import CustomScriptsV2 from 'features/library-items/items/custom-script';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  i18nOsRequirements,
  osRequirements,
  publishers,
  publishersTranslations,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const customApps = {
  'Custom Apps': new LibraryItemConfig({
    getName: () => i18n.t('Custom Apps'),
    getDescription: () =>
      i18n.t(
        'Upload and configure a .pkg, .dmg or .zip for the Kandji agent to install on your macOS devices.',
      ),
    name: 'Custom Apps',
    description:
      'Upload and configure a .pkg, .dmg or .zip for the Kandji agent to install on your macOS devices.',
    type: apiTypes.CUSTOM_APP,
    publisher: publishers.KANDJI,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: svgs['Custom App'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'custom-apps',
    getUrl: ({ id }) => `/library/custom-apps/${id}`,
    getAddUrl: () => `/library/custom-apps/add`,
    component: CustomAppsV2,
  }),
  'Custom Scripts': new LibraryItemConfig({
    getName: () => i18n.t('Custom Scripts'),
    getDescription: () =>
      i18n.t(`Run any type of script supported by macOS. Choose to run once per device or continuously,
     and add an optional remediation script that can be run when needed.
     If you do not specify a shell or interpreter, scripts will run in shell (/bin/sh).`),
    name: 'Custom Scripts',
    description: `Run any type of script supported by macOS. Choose to run once per device or continuously,
     and add an optional remediation script that can be run when needed.
     If you do not specify a shell or interpreter, scripts will run in shell (/bin/sh).`,
    type: apiTypes.CUSTOM_SCRIPT,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_11)],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: svgs['Custom Script'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'custom-scripts',
    getUrl: ({ id }) => `/library/custom-scripts/${id}`,
    getAddUrl: () => `/library/custom-scripts/add`,
    component: CustomScriptsV2,
  }),
  'Custom Printers': new LibraryItemConfig({
    getName: () => i18n.t('Custom Printers'),
    getDescription: () =>
      i18n.t(
        `Configure custom printers and printer drivers to be installed using the Kandji Agent. Custom printers can be continously enforced, installed once, or offered through Self Service.`,
      ),
    name: 'Custom Printers',
    description: `Configure custom printers and printer drivers to be installed using the Kandji Agent. Custom printers can be continously enforced, installed once, or offered through Self Service.`,
    type: apiTypes.CUSTOM_PRINTER,
    publisher: publishers.APPLE,
    getPublisher: () => publishersTranslations(publishers.APPLE),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_11)],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: svgs['Custom Printer'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'custom-printers',
    getUrl: ({ id }) => `/library/custom-printers/${id}`,
    getAddUrl: () => `/library/custom-printers/add`,
    component: CustomPrintersV2,
  }),
  Bookmarks: new LibraryItemConfig({
    getName: () => i18n.t('Bookmarks'),
    name: 'Bookmarks',
    description:
      'Create a bundle of links to specified webpages and display them in Self Service under Bookmarks, and optionally, in a specified category.',
    getDescription: () =>
      i18n.t(
        'Create a bundle of links to specified webpages and display them in Self Service under Bookmarks, and optionally, in a specified category.',
      ),
    type: apiTypes.BOOKMARK,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD, deviceTypes.MAC],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
    ],
    getOsRequirements: () => [
      i18nOsRequirements(osRequirements.MAC_10_7),
      i18nOsRequirements(osRequirements.IOS_4_0),
      i18nOsRequirements(osRequirements.IPAD_13_0),
    ],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS, // TODO: determine the correct category
    icon: svgs.Bookmarks,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'bookmarks',
    getUrl: /* istanbul ignore next */ ({ id }) => `/library/bookmarks/${id}`,
    getAddUrl: /* istanbul ignore next */ () => `/library/bookmarks/add`,
    component: /* istanbul ignore next */ (props) => <Bookmarks {...props} />,
  }),
  'App Blocking': new LibraryItemConfig({
    getName: () => i18n.t('App Blocking'),
    getDescription: () =>
      i18n.t(`Configure settings to block apps from running on macOS.`),
    name: 'App Blocking',
    description: `Configure settings to block apps from running on macOS.`,
    type: apiTypes.APP_BLOCKING,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_11)],
    category: categories.GENERAL,
    identifier: '',
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: svgs['AppBlocking'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    routeType: 'app-blocking',
    getUrl: ({ id }) => `/library/app-blocking/${id}`,
    getAddUrl: () => `/library/app-blocking/add`,
    component(props) {
      return <AppBlocking {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
};

export default customApps;
