/* istanbul ignore file */
import React, { useContext, useCallback } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import type { DiskManagementProps } from './disk-management.types';
import initial from './initial-state';
import Management from './sections/management';
import { diskManagementService } from './service/disk-management-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useDiskManagementService from './service/use-disk-management-service';

const DiskManagementPage = (props: DiskManagementProps) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const managementUpdate = useCallback(update('management', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={diskManagementService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <Management
        settings={model.management}
        isDisabled={pageState.isDisabled}
        update={managementUpdate}
      />
    </LibraryItemPage>
  );
};

const DiskManagement = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useDiskManagementService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <DiskManagementPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="disk-management-v2"
    />
  );
};

export default DiskManagement;
