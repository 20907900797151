import {
  Box,
  Chip,
  Flex,
  Grid,
  Icon,
  type IconNames,
  Text,
  css,
  styled,
} from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import { Link } from 'react-router-dom';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';
import { constants } from '../../common';
import { OverflowingChipList } from '../../common/components/OverflowingChipList';
import ThreatListHashPathCode from '../../common/components/ThreatListHashPathCode';
import ThreatListStatusBadge from '../../common/components/ThreatListStatusBadge';
import type {
  BehavioralThreatDetail,
  BehavioralThreatStatus,
} from '../../threat.types';

const SummaryDetails = styled(Flex, {
  gap: '$3',
  pt: '$1',
  alignItems: 'start',
});

const PathCodeWrapper = styled(Box, {
  '& > div': { width: 'auto' },
  '& code': { flexGrow: 0 },
  overflow: 'hidden',
});

const TextBold = styled(Text, { fontWeight: '$medium', color: '$neutral90' });

const TextTruncated = styled(Text, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  maxWidth: '100%',
  fontWeight: '$medium',
  a: {
    color: '$neutral90',
    lineHeight: '18px',
  },
});

const CapitalizedChip = styled(Chip, {
  '&:not(:disabled)': {
    textTransform: 'capitalize',
    backgroundColor: '$neutral0',
    border: '1px solid $neutral30',
    color: '$neutral60',
    padding: '1px 5px',
  },
});

const StyledGrid = styled(Grid, {
  fontSize: '$1',
  color: '$neutral70',
  gap: 6,
  gridTemplateColumns: '126px 1fr',
  flex: 1,
  alignItems: 'stretch',
  gridAutoRows: '22px',
});

const GridCell = styled(Flex, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  flexDirection: 'column',
});

const GridCellHead = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const infoIconStyle = css({
  color: '$neutral70',
  '& > svg': { height: 12, width: 12, verticalAlign: '-2px' },
});

const TooltipIconsWrapper = styled(Flex, {
  gap: 2,
});

const getStatusLabel = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP(status);

const getStatusIcon = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

const getStatusColor = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

const IconTooltip = ({ text, icon }: { text: string; icon: IconNames }) => (
  <EDRTooltip label={text}>
    <span className={infoIconStyle().className}>
      <Icon name={icon} />
    </span>
  </EDRTooltip>
);

const ThreatListSplitViewBody = (props: {
  threat: BehavioralThreatDetail;
  query: string | undefined;
}) => {
  const { threat, query } = props;

  return (
    <Flex flow="column" gap="lg" flex={1}>
      <SummaryDetails css={{ pt: '$2' }}>
        <StyledGrid
          css={{
            gridAutoRows: '18px',
            gridTemplateRows: '18px 18px minmax(18px, auto)',
          }}
        >
          <GridCellHead>
            <Text variant="description">{i18n.t('Threat status')}</Text>
          </GridCellHead>
          <GridCellHead>
            <ThreatListStatusBadge
              color={getStatusColor(threat.threat_status)}
              icon={getStatusIcon(threat.threat_status)}
            >
              {getStatusLabel(threat.threat_status)}
            </ThreatListStatusBadge>
          </GridCellHead>

          <GridCellHead>
            <Text variant="description">{i18n.t('Detected on')}</Text>
          </GridCellHead>
          <GridCellHead>
            {i18n.format.datetime(threat.detection_date)}
          </GridCellHead>

          <GridCellHead css={{ alignItems: 'self-start', pt: 1 }}>
            <Text variant="description">{i18n.t('Description')}</Text>
          </GridCellHead>
          <GridCellHead css={{ whiteSpace: 'unset', wordBreak: 'break-all' }}>
            <Text>{threat.description?.length ? threat.description : '-'}</Text>
          </GridCellHead>

          <GridCellHead>
            <Text variant="description">{i18n.t('Rule version')}</Text>
          </GridCellHead>
          <GridCellHead>{threat.rule_version}</GridCellHead>

          <GridCellHead>
            <Text variant="description">{i18n.t('Malware family')}</Text>
          </GridCellHead>
          <GridCellHead>
            {threat.malware_families?.length ? (
              <OverflowingChipList
                chips={threat?.malware_families}
                searchTerm={query}
              />
            ) : (
              '-'
            )}
          </GridCellHead>

          <GridCellHead>
            <Text variant="description">{i18n.t('Informational tags')}</Text>
          </GridCellHead>
          <GridCellHead>
            {threat.informational_tags?.length ? (
              <OverflowingChipList chips={threat.informational_tags} />
            ) : (
              '-'
            )}
          </GridCellHead>
        </StyledGrid>
      </SummaryDetails>
      <Box wFull css={{ height: '1px', backgroundColor: '$neutral20' }} />
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <Text variant="description">{i18n.t('Blueprint')}</Text>
          </GridCell>
          <GridCell>
            <TextTruncated>
              {threat.blueprint_id ? (
                <Link
                  to={`${links.blueprints}/${threat.blueprint_id}`}
                  target="_blank"
                >
                  {threat.blueprint_name || threat.blueprint_id}
                </Link>
              ) : (
                '-'
              )}
            </TextTruncated>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Avert Library Item')}</Text>
          </GridCell>
          <GridCell
            css={{ height: 'fit-content', flexDirection: 'row', gap: '$1' }}
          >
            <TextTruncated css={{ width: 'fit-content' }}>
              {threat.avert_library_item_id ? (
                <Link
                  to={`/library/avert/${threat.avert_library_item_id}`}
                  target="_blank"
                >
                  {threat.avert_library_item_name ||
                    threat.avert_library_item_id}
                </Link>
              ) : (
                '-'
              )}
            </TextTruncated>
            {threat.avert_library_item_id && threat.mal_posture_mode && (
              <CapitalizedChip
                size="compact"
                color="neutral"
                label={i18n.t('Posture mode: {malPostureMode}', {
                  malPostureMode: threat.mal_posture_mode,
                })}
                css={{ '&:not(:disabled)': { textTransform: 'unset' } }}
              />
            )}
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
      <SummaryDetails>
        <StyledGrid>
          <GridCell css={{ overflow: 'unset' }}>
            <TextBold>
              {i18n.t('Parent process')}{' '}
              <IconTooltip
                text={i18n.t(
                  'The process parent for triggering the threat event.',
                )}
                icon="circle-info"
              />
            </TextBold>
          </GridCell>
          <GridCell></GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Process name')}</Text>
          </GridCell>
          <GridCell css={{ flexDirection: 'row', gap: '$1' }}>
            {threat.parent_process_name}
            <TooltipIconsWrapper>
              {threat.parent_process_id && (
                <IconTooltip
                  text={`${i18n.t('Process ID')}: ${threat.parent_process_id}`}
                  icon="certificates"
                />
              )}
              {threat.parent_process_owner && (
                <IconTooltip
                  text={`${i18n.t('User')}: ${threat.parent_process_owner}`}
                  icon="circle-user"
                />
              )}
            </TooltipIconsWrapper>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Command line')}</Text>
          </GridCell>
          <GridCell>
            <PathCodeWrapper>
              <ThreatListHashPathCode
                value={threat.parent_process_command_line}
                highlight={query}
              />
            </PathCodeWrapper>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Image path')}</Text>
          </GridCell>
          <GridCell>
            {threat.parent_process_image_path ? (
              <PathCodeWrapper>
                <ThreatListHashPathCode
                  value={threat.parent_process_image_path}
                  highlight={query}
                />
              </PathCodeWrapper>
            ) : (
              '-'
            )}
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('SHA256')}</Text>
          </GridCell>
          <GridCell>
            {threat.parent_process_sha256 ? (
              <PathCodeWrapper>
                <ThreatListHashPathCode
                  value={threat.parent_process_sha256}
                  highlight={query}
                />
              </PathCodeWrapper>
            ) : (
              '-'
            )}
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <TextBold>
              {i18n.t('Target process')}{' '}
              <IconTooltip
                text={i18n.t(
                  'The process started by the parent process or targeted by the threat event.',
                )}
                icon="circle-info"
              />
            </TextBold>
          </GridCell>
          <GridCell></GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Process name')}</Text>
          </GridCell>
          <GridCell css={{ flexDirection: 'row', gap: '$1' }}>
            {threat.target_process_name}
            <TooltipIconsWrapper>
              {threat.target_process_id && (
                <IconTooltip
                  text={`${i18n.t('Process ID')}: ${threat.target_process_id}`}
                  icon="certificates"
                />
              )}
              {threat.target_process_owner && (
                <IconTooltip
                  text={`${i18n.t('User')}: ${threat.target_process_owner}`}
                  icon="circle-user"
                />
              )}
            </TooltipIconsWrapper>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Command line')}</Text>
          </GridCell>
          <GridCell>
            <PathCodeWrapper>
              <ThreatListHashPathCode
                value={threat.target_command_line}
                highlight={query}
              />
            </PathCodeWrapper>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Image path')}</Text>
          </GridCell>
          <GridCell>
            {threat.target_image_path ? (
              <PathCodeWrapper>
                <ThreatListHashPathCode
                  value={threat.target_image_path}
                  highlight={query}
                />
              </PathCodeWrapper>
            ) : (
              '-'
            )}
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('SHA256')}</Text>
          </GridCell>
          <GridCell>
            {threat.target_process_sha256 ? (
              <PathCodeWrapper>
                <ThreatListHashPathCode
                  value={threat.target_process_sha256}
                  highlight={query}
                />
              </PathCodeWrapper>
            ) : (
              '-'
            )}
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
    </Flex>
  );
};

export default ThreatListSplitViewBody;
