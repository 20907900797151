/* istanbul ignore file */

import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import {
  InstallationCard,
  useSsInstall,
} from 'features/library-items/template';
import { i18n } from 'src/i18n';

import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutDefaultsPreset from '../../common/self-service-card/drawer/presets/without-defaults-preset';
import LibraryContext from '../../routes/library.context';
import defaultIcon from './cp-library.png';
import GeneralCard from './general-card';
import initialState from './initial-state';
import PrinterFileCard from './printer-file-card';
import CustomPrinterService, {
  customPrinterService,
} from './service/custom-printer-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCustomPrinterService from './service/use-custom-printer-service';

const CustomPrinterPage = (props) => {
  const { model, setModel, pageState, handlers } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const { itemConfig } = useContext(LibraryContext);

  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateGeneral = useCallback(update('general', setModel), []);
  const updateFile = useCallback(update('printerFile', setModel), []);

  const installationOptions = [
    {
      label: i18n.t('Install printer once per device'),
      value: CustomPrinterService.installationTypes.INSTALL_ONCE,
    },
    {
      label: i18n.t('Install printer and continuously enforce'),
      value: CustomPrinterService.installationTypes.CONTINUOUSLY_ENFORCE,
    },
    {
      label: i18n.t('Install on-demand from Self Service'),
      value: CustomPrinterService.installationTypes.NO_ENFORCEMENT,
    },
  ];

  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: CustomPrinterService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || itemConfig.getName()}
      defaultIcon={defaultIcon}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      askFlush={() =>
        model.installation.type ===
          CustomPrinterService.installationTypes.INSTALL_ONCE &&
        model.isActive === true
      }
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={customPrinterService}
      isIconEditable
      supportsRules
      supportsDuplication
    >
      <InstallationCard
        setting={model.installation}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        options={installationOptions}
      />
      <SelfServiceCard
        setting={model.selfService}
        update={updateSs}
        isDisabled={pageState.isDisabled}
        defaults={{
          icon: model.iconSrc || defaultIcon,
          name: model.name,
        }}
        DrawerContent={WithoutDefaultsPreset}
        canBeDisabled={!isSsForced}
        isSubmitted={pageState.isSubmitted}
      />
      <GeneralCard
        setting={model.general}
        update={updateGeneral}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
        onValidate={handlers.onValidate}
      />
      <PrinterFileCard
        setting={model.printerFile}
        update={updateFile}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
        onValidate={handlers.onValidate}
      />
    </LibraryItemPage>
  );
};

const CustomPrinter = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useCustomPrinterService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <CustomPrinterPage {...pageProps} />;
};

export default CustomPrinter;
