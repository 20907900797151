import type {
  PrismCategorySchema,
  PrismCategoryUri,
} from '../../types/prism.types';
import { getSupportedDeviceFamiliesByPrismCategory } from '../PrismTable/utils/tooltipUtils';

export const getIconName = (attribute: PrismCategoryUri) => {
  switch (attribute) {
    case 'device_information':
      return 'devices';
    case 'filevault':
      return 'sf-key';
    case 'startup_settings':
      return 'sf-startup-settings';
    case 'launch_agents_and_daemons':
      return 'gears';
    case 'activation_lock':
      return 'cloud-lock';
    case 'system_extensions':
      return 'system-extensions';
    case 'gatekeeper_and_xprotect':
      return 'shield-lock';
    case 'transparency_database':
      return 'transparency-database';
    case 'kernel_extensions':
      return 'kernel-extensions';
    case 'local_users':
      return 'local-users';
    case 'installed_profiles':
      return 'installed-profiles';
    case 'application_firewall':
      return 'application-firewall';
    case 'apps':
      return 'apps';
    case 'desktop_and_screensaver':
      return 'desktop-and-screensaver';
    case 'certificates':
      return 'certificates';
    case 'cellular':
      return 'cellular';
    default:
      return 'octagon-exclamation';
  }
};

export function isDisabled(
  deviceFamilies: string[],
  attribute: PrismCategoryUri,
) {
  if (!deviceFamilies || deviceFamilies?.length === 0) {
    return false;
  }
  const iosAppleVisionPro = deviceFamilies.includes('Vision');
  const supportedDevices = getSupportedDeviceFamiliesByPrismCategory(
    attribute,
    iosAppleVisionPro,
  );

  const globalFilterDeviceFamilies = deviceFamilies.map((d) => {
    const lowerCased = d.toLowerCase();
    if (lowerCased === 'appletv') {
      return 'apple_tv';
    }
    return lowerCased;
  });

  const isSupported = supportedDevices.some((device) =>
    globalFilterDeviceFamilies.includes(device),
  );

  return !isSupported;
}

/**
 * Sort Prism categories by display name, but puts device information first
 */
export const sortPrismCategories = (
  a: PrismCategorySchema,
  b: PrismCategorySchema,
): number => {
  if (a.uri === 'device_information') {
    return -1;
  }
  if (b.uri === 'device_information') {
    return 1;
  }
  return a.display_name.localeCompare(b.display_name);
};
