/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Vulnerability } from '../../vulnerability.types';

const useGetCVEImpactingTheMostDevices = (
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['cve-impacting-most-devices', ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi('v1/dashboards/cve_impacting_most_devices').get(params),
  });

  const data = apiRes?.data;
  const cveId: Vulnerability['cve_id'] = data?.cve_id || '';
  const count: number = data?.count || 0;

  return { cveId, count, isLoading };
};

export { useGetCVEImpactingTheMostDevices };
