import featureFlags from 'src/config/feature-flags';
/* istanbul ignore file */
import { i18n } from 'src/i18n';
import AppRestrictionsRow from '../components/AppRestrictionsRow';
import BundleAppRow from '../components/BundleAppRow';
import { getRestrictionCategories } from './categories';
import type { RestrictionConfig } from './common';
import {
  addIdFieldToValue,
  extractValueArrayFromObject,
  fieldTypes,
  flags,
  interpolateArrayValueIntoCheckedObject,
  removeIdFieldFromValue,
  tagKinds,
  transformFromAppRestriction,
  transformToAppRestriction,
  transformValueNumberToString,
  transformValueStringToNumber,
} from './common';

const APPLE_VISION_PRO_FF = 'ios-apple-vision-pro';

const getDescription = () => {
  const isVisionEnabled = featureFlags.getFlag(APPLE_VISION_PRO_FF);

  return {
    allowAssistant: isVisionEnabled
      ? i18n.t(
          'Disables Siri. Available in iOS 5 and later, and macOS 10.15 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables Siri. Available in iOS 5 and later, and macOS 10.15 and later.',
        ),
    allowAccountModification: isVisionEnabled
      ? i18n.t(
          `Users are prevented from adding, removing, or modifying accounts (mail, contacts, calendars). Requires a supervised device. Available in iOS 7 and later, visionOS 2.0 and later, and macOS 14 and later.`,
        )
      : i18n.t(
          `Users are prevented from adding, removing, or modifying accounts (mail, contacts, calendars). Requires a supervised device. Available in iOS 7 and later, and macOS 14 and later.`,
        ),
    allowAirDrop: isVisionEnabled
      ? i18n.t(
          'Disables AirDrop file sharing. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 7, visionOS 2.0 and later, and macOS 10.13 and later.',
        )
      : i18n.t(
          'Disables AirDrop file sharing. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 7, and macOS 10.13 and later.',
        ),
    allowPasswordAutoFill: isVisionEnabled
      ? i18n.t(
          "Disables the AutoFill Passwords feature, and the user isn't prompted to use saved passwords in apps, including Safari. This restriction also disables Automatic Strong Passwords, and strong passwords are no longer suggested to users. iPhone and iPad devices must be supervised. Available in iOS 12 and later, visionOS 2.0 and later, and macOS 10.14 and later.",
        )
      : i18n.t(
          "Disables the AutoFill Passwords feature, and the user isn't prompted to use saved passwords in apps, including Safari. This restriction also disables Automatic Strong Passwords, and strong passwords are no longer suggested to users. iPhone and iPad devices must be supervised. Available in iOS 12 and later, and macOS 10.14 and later.",
        ),
    forceAuthenticationBeforeAutoFill: isVisionEnabled
      ? i18n.t(
          'Users must authenticate before passwords or credit card information can be autofilled in apps, including Safari. Only supported on devices with Face ID or Touch ID. Requires a supervised device. Available in iOS 11 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Users must authenticate before passwords or credit card information can be autofilled in apps, including Safari. Only supported on devices with Face ID or Touch ID. Requires a supervised device. Available in iOS 11 and later.',
        ),
    allowPasswordSharing: isVisionEnabled
      ? i18n.t(
          'Users are prevented from sharing passwords with the Airdrop Passwords feature. iPhone and iPad devices must be supervised. Available in iOS 12 and later, visionOS 2.0 and later, and macOS 10.14 and later.',
        )
      : i18n.t(
          'Users are prevented from sharing passwords with the Airdrop Passwords feature. iPhone and iPad devices must be supervised. Available in iOS 12 and later, and macOS 10.14 and later.',
        ),
    forceAirDropUnmanaged: isVisionEnabled
      ? i18n.t(
          'Forces managed apps to treat AirDrop as an unmanaged destination. Available in iOS 9 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Forces managed apps to treat AirDrop as an unmanaged destination. Available in iOS 9 and later.',
        ),
    allowAppInstallation: isVisionEnabled
      ? i18n.t(
          'Users are prevented from installing or updating apps using the App Store or host apps (iTunes, Configurator). Also prevents installation and use of marketplace apps and marketplace-hosted apps in the European Union. The App Store is disabled, and its icon is removed from the Home screen. Requires a supervised device. Available in iOS 4 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Users are prevented from installing or updating apps using the App Store or host apps (iTunes, Configurator). Also prevents installation and use of marketplace apps and marketplace-hosted apps in the European Union. The App Store is disabled, and its icon is removed from the Home screen. Requires a supervised device. Available in iOS 4 and later.',
        ),
    allowUIAppInstallation: isVisionEnabled
      ? i18n.t(
          'Users are prevented from installing or updating apps using the App Store. The App Store is disabled, and its icon is removed from the Home screen. Host apps (iTunes, Configurator) are still allowed to be used to install and update apps. Requires a supervised device. Available in iOS 9 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Users are prevented from installing or updating apps using the App Store. The App Store is disabled, and its icon is removed from the Home screen. Host apps (iTunes, Configurator) are still allowed to be used to install and update apps. Requires a supervised device. Available in iOS 9 and later.',
        ),
    allowSystemAppRemoval: isVisionEnabled
      ? i18n.t(
          'Users are unable to remove system apps from the device. Requires a supervised device. Available in iOS 11 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Users are unable to remove system apps from the device. Requires a supervised device. Available in iOS 11 and later.',
        ),
    allowVideoConferencing: isVisionEnabled
      ? i18n.t(
          'The FaceTime app is disabled, and its icon is removed from the Home screen. Requires a supervised device. Available in iOS 4 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'The FaceTime app is disabled, and its icon is removed from the Home screen. Requires a supervised device. Available in iOS 4 and later.',
        ),
    allowOpenFromManagedToUnmanaged: isVisionEnabled
      ? i18n.t(
          'Documents in managed apps and accounts only open in other managed apps and accounts. Available in iOS 7 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Documents in managed apps and accounts only open in other managed apps and accounts. Available in iOS 7 and later.',
        ),
    allowOpenFromUnmanagedToManaged: isVisionEnabled
      ? i18n.t(
          'Documents in unmanaged apps and accounts only open in other unmanaged apps and accounts. Available in iOS 7 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Documents in unmanaged apps and accounts only open in other unmanaged apps and accounts. Available in iOS 7 and later.',
        ),
    allowManagedToWriteUnmanagedContacts: isVisionEnabled
      ? i18n.t(
          'Managed apps can write contacts to unmanaged contacts accounts. Available in iOS 12 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Managed apps can write contacts to unmanaged contacts accounts. Available in iOS 12 and later.',
        ),
    allowUnmanagedToReadManagedContacts: isVisionEnabled
      ? i18n.t(
          'Unmanaged apps can read from managed contacts accounts. Available in iOS 12 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Unmanaged apps can read from managed contacts accounts. Available in iOS 12 and later.',
        ),
    allowDiagnosticSubmission: isVisionEnabled
      ? i18n.t(
          'Prevents the device from automatically submitting diagnostic reports to Apple. Available in iOS 6 and later, visionOS 2.0 and later, and macOS 10.13 and later.',
        )
      : i18n.t(
          'Prevents the device from automatically submitting diagnostic reports to Apple. Available in iOS 6 and later, and macOS 10.13 and later.',
        ),
    allowDiagnosticSubmissionModification: isVisionEnabled
      ? i18n.t(
          'Disables changing the diagnostic submission and app analytics settings in the Diagnostics & Usage UI in Settings. Requires a supervised device. Available in iOS 9.3.2 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables changing the diagnostic submission and app analytics settings in the Diagnostics & Usage UI in Settings. Requires a supervised device. Available in iOS 9.3.2 and later.',
        ),
    allowFilesNetworkDriveAccess: isVisionEnabled
      ? i18n.t(
          'Prevents connecting to network drives in the Files app. Requires a supervised device. Available in iOS 13.0 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents connecting to network drives in the Files app. Requires a supervised device. Available in iOS 13.0 and later.',
        ),
    allowCloudBackup: isVisionEnabled
      ? i18n.t(
          'Disables backing up the device to iCloud. Requires a supervised device. Available in iOS 5, visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables backing up the device to iCloud. Requires a supervised device. Available in iOS 5 and later.',
        ),
    allowManagedAppsCloudSync: isVisionEnabled
      ? i18n.t(
          'Prevents managed apps from using iCloud sync. Available in iOS 8 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents managed apps from using iCloud sync. Available in iOS 8 and later.',
        ),
    allowActivityContinuation: isVisionEnabled
      ? i18n.t(
          'Disables activity continuation. Available in iOS 8, macOS 10.15 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables activity continuation. Available in iOS 8, macOS 10.15 and later.',
        ),
    allowCloudPrivateRelay: isVisionEnabled
      ? i18n.t(
          'Prevents the user from turning on iCloud Private Relay. Available in iOS 15, iPadOS 15, visionOS 2.0, macOS 12, and later.',
        )
      : i18n.t(
          'Prevents the user from turning on iCloud Private Relay. Available in iOS 15, iPadOS 15, macOS 12, and later.',
        ),
    forceWiFiWhitelisting: isVisionEnabled
      ? i18n.t(
          'Devices can only join Wi-Fi networks configured by a configuration profile. Requires a supervised device. Available in iOS 10.3 and later, visionOS 2.0 and later.',
        )
      : i18n.t(
          'Devices can only join Wi-Fi networks configured by a configuration profile. Requires a supervised device. Available in iOS 10.3 and later.',
        ),
    allowVPNCreation: isVisionEnabled
      ? i18n.t(
          'Prevents the creation of VPN configurations. Requires a supervised device. Available in iOS 11 and later, visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents the creation of VPN configurations. Requires a supervised device. Available in iOS 11 and later.',
        ),
    allowUntrustedTLSPrompt: isVisionEnabled
      ? i18n.t(
          'Automatically rejects untrusted HTTPS certificates without prompting the user. Available in iOS 5 and later, visionOS 1.1 and later.',
        )
      : i18n.t(
          'Automatically rejects untrusted HTTPS certificates without prompting the user. Available in iOS 5 and later.',
        ),
    forceAutomaticDateAndTime: isVisionEnabled
      ? i18n.t(
          "Enables the Set Automatically feature in Date & Time, and this can't be disabled by the user. The device's time zone is updated only when the device can determine its location using a cellular connection or Wi-Fi with location services enabled. Requires a supervised device. Available in iOS 12 and later, visionOS 2.0 and later, and tvOS 12.2 and later.",
        )
      : i18n.t(
          "Enables the Set Automatically feature in Date & Time, and this can't be disabled by the user. The device's time zone is updated only when the device can determine its location using a cellular connection or Wi-Fi with location services enabled. Requires a supervised device. Available in iOS 12 and later and tvOS 12.2 and later.",
        ),
    allowCamera: isVisionEnabled
      ? i18n.t(
          'Disables the camera, and its icon is removed from the Home screen. Users are unable to take photographs. iPhone and iPad devices must be supervised. Available in iOS 4, visionOS 2.0, and macOS 10.11 and later.',
        )
      : i18n.t(
          'Disables the camera, and its icon is removed from the Home screen. Users are unable to take photographs. iPhone and iPad devices must be supervised. Available in iOS 4, and macOS 10.11 and later.',
        ),
    allowEraseContentAndSettings: isVisionEnabled
      ? i18n.t(
          'Disable Erase All Content and Settings. Requires a supervised device. Available in iOS 8, macOS 12, visionOS 2.0, and later.',
        )
      : i18n.t(
          'Disable Erase All Content and Settings. Requires a supervised device. Available in iOS 8, macOS 12, and later.',
        ),
    allowUIConfigurationProfileInstallation: isVisionEnabled
      ? i18n.t(
          'Prohibits the user from installing configuration profiles and certificates interactively. Requires a supervised device. Available in iOS 6 and later, macOS 13 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prohibits the user from installing configuration profiles and certificates interactively. Requires a supervised device. Available in iOS 6 and later, macOS 13 and later.',
        ),
    allowNotificationsModification: isVisionEnabled
      ? i18n.t(
          'Prevents changing notification settings. Requires a supervised device. Available in iOS 9.3 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents changing notification settings. Requires a supervised device. Available in iOS 9.3 and later.',
        ),
    allowEnablingRestrictions: isVisionEnabled
      ? i18n.t(
          'In iOS 12 or later, disables the “Enable ScreenTime” option in the ScreenTime UI in Settings and disables ScreenTime if already enabled. Prior to iOS 12, this disables the “Enable Restrictions” option in the Restrictions UI in Settings. Requires a supervised device. Available in iOS 8 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'In iOS 12 or later, disables the “Enable ScreenTime” option in the ScreenTime UI in Settings and disables ScreenTime if already enabled. Prior to iOS 12, this disables the “Enable Restrictions” option in the Restrictions UI in Settings. Requires a supervised device. Available in iOS 8 and later.',
        ),
    allowScreenShot: isVisionEnabled
      ? i18n.t(
          'Disables saving a screenshot of the display and capturing a screen recording. Available in iOS 4 and later, macOS 10.14.4 and later, visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables saving a screenshot of the display and capturing a screen recording. Available in iOS 4 and later, macOS 10.14.4 and later.',
        ),
    allowEnterpriseAppTrust: isVisionEnabled
      ? i18n.t(
          "Remove the Trust Enterprise Developer button in Settings > General > Profiles & Device Management, preventing apps from being provisioned by universal provisioning profiles. This restriction applies to free developer accounts. It doesn't apply to enterprise app developers who are trusted because their apps were pushed through APNs. It also doesn't revoke previously granted trust. Available in iOS 9 and later, and visionOS 2.0 and later.",
        )
      : i18n.t(
          "Remove the Trust Enterprise Developer button in Settings > General > Profiles & Device Management, preventing apps from being provisioned by universal provisioning profiles. This restriction applies to free developer accounts. It doesn't apply to enterprise app developers who are trusted because their apps were pushed through APNs. It also doesn't revoke previously granted trust. Available in iOS 9 and later.",
        ),
    allowPasscodeModification: isVisionEnabled
      ? i18n.t(
          'Prevents the user from adding, changing, or removing device passcodes (iPhone and iPad) or user account passcodes (Mac). iPhone and iPad devices must be supervised. Available in iOS 9 and later, macOS 10.13 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents the user from adding, changing, or removing device passcodes (iPhone and iPad) or user account passcodes (Mac). iPhone and iPad devices must be supervised. Available in iOS 9 and later, and macOS 10.13 and later.',
        ),
    allowFingerprintModification: isVisionEnabled
      ? i18n.t(
          'Prevents the user from modifying Touch ID or Face ID. Requires a supervised device. Available in iOS 8.3 and later, macOS 14 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Prevents the user from modifying Touch ID or Face ID. Requires a supervised device. Available in iOS 8.3 and later, macOS 14 and later.',
        ),
    allowFingerprintForUnlock: isVisionEnabled
      ? i18n.t(
          'Prevents Touch ID or Face ID from unlocking a device.' +
            ' Available in iOS 7 and later, visionOS 2.0 and later, and macOS 10.12.4 and later.',
        )
      : i18n.t(
          'Prevents Touch ID or Face ID from unlocking a device.' +
            ' Available in iOS 7 and later and macOS 10.12.4 and later.',
        ),
    allowApplePersonalizedAdvertising: isVisionEnabled
      ? i18n.t(
          'Limits Apple personalized advertising. Available in iOS 14, visionOS 2.0 and later, and macOS 12 and later.',
        )
      : i18n.t(
          'Limits Apple personalized advertising. Available in iOS 14 and macOS 12 and later.',
        ),
    allowCloudKeychainSync: isVisionEnabled
      ? i18n.t(
          'Disables syncing the Keychain to iCloud. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 7 and later, visionOS 2.0 and later, and macOS 10.12 and later.',
        )
      : i18n.t(
          'Disables syncing the Keychain to iCloud. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 7 and later and macOS 10.12 and later.',
        ),
    allowCloudDocumentSync: isVisionEnabled
      ? i18n.t(
          'Disables document and app data syncing to iCloud. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 5 and later, visionOS 2.0 and later, and macOS 10.11 and later.',
        )
      : i18n.t(
          'Disables document and app data syncing to iCloud. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 5 and later and macOS 10.11 and later.',
        ),
    safariAllowAutoFill: isVisionEnabled
      ? i18n.t(
          'Disables Safari Autofill. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 4 and later, visionOS 2.0 and later, and macOS 10.13 and later.',
        )
      : i18n.t(
          'Disables Safari Autofill. iPhone and iPad devices must be supervised.' +
            ' Available in iOS 4 and later and macOS 10.13 and later.',
        ),
    forceOnDeviceOnlyDictation: isVisionEnabled
      ? i18n.t(
          'Prevent the device from connecting to the Siri servers for dictation. Available in iOS 14.5, iPadOS 14.5 and later, visionOS 2.0 and later, and Mac computers with Apple silicon running macOS 14 and later.',
        )
      : i18n.t(
          'Prevent the device from connecting to the Siri servers for dictation. Available in iOS 14.5, iPadOS 14.5 and later, and Mac computers with Apple silicon running macOS 14 and later.',
        ),
    allowCloudPhotoLibrary: isVisionEnabled
      ? i18n.t(
          'Disables the iCloud Photo Library. Available in iOS 9 and later, macOS 10.12 and later, and visionOS 2.0 and later.',
        )
      : i18n.t(
          'Disables the iCloud Photo Library. Available in iOS 9 and later, and macOS 10.12 and later.',
        ),
  };
};

export const accountsAndPasswordsConfig = () => [
  {
    key: 'allowAccountModification',
    name: i18n.t('Disallow changing account settings'),
    description: getDescription().allowAccountModification,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowPasswordAutoFill',
    name: i18n.t('Disallow AutoFill Passwords'),
    description: getDescription().allowPasswordAutoFill,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'forceAuthenticationBeforeAutoFill',
    name: i18n.t('Require authentication before using AutoFill Passwords'),
    description: getDescription().forceAuthenticationBeforeAutoFill,
    dependencies: [{ key: 'allowPasswordAutoFill', mustBe: false }],
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
  },
  {
    key: 'allowPasswordSharing',
    name: i18n.t('Disallow Password Sharing'),
    description: getDescription().allowPasswordSharing,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowPasswordProximityRequests',
    name: i18n.t('Disallow proximity based password sharing requests'),
    description: i18n.t(
      'Prevents devices from requesting passwords from nearby devices.' +
        ' iPhone and iPad devices must be supervised.' +
        ' Available in iOS 12 and later, macOS 10.14 and later, and tvOS 12 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().tv,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowProximitySetupToNewDevice',
    name: i18n.t('Disallow Proximity Setup for New Devices'),
    description: i18n.t(
      'Disables the prompt to set up new devices that are nearby.' +
        ' Requires a supervised device. Available in iOS 11 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
];

export const airdropConfig = () => [
  {
    key: 'allowAirDrop',
    name: i18n.t('Disallow AirDrop'),
    description: getDescription().allowAirDrop,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'forceAirDropUnmanaged',
    name: i18n.t('Disallow sharing managed documents using AirDrop'),
    description: getDescription().forceAirDropUnmanaged,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
  },
];

export const airplayConfig = () => [
  {
    key: 'allowAirPlayIncomingRequests',
    name: i18n.t('Disallow incoming AirPlay requests'),
    description: i18n.t(
      'Disables incoming AirPlay requests. Requires a supervised device.' +
        ' Available in tvOS 10.2 and later, and macOS 12.3 and later.',
    ),
    tags: [tagKinds().mac, tagKinds().tv, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'forceAirPlayIncomingRequestsPairingPassword',
    name: i18n.t('Require password for incoming AirPlay requests'),
    description: i18n.t(
      'Forces all devices sending AirPlay requests to the device to use a pairing password.' +
        ' Available in iOS 7.1 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone],
  },
  {
    key: 'forceAirPlayOutgoingRequestsPairingPassword',
    name: i18n.t('Require password for outgoing AirPlay requests'),
    description: i18n.t(
      'Forces all devices receiving AirPlay requests from the device to use a pairing password.' +
        ' Available in iOS 7.1 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone],
  },
];

export const airprintConfig = () => [
  {
    key: 'allowAirPrint',
    name: i18n.t('Disallow AirPrint'),
    description: i18n.t(
      'Disables AirPrint printing. Requires a supervised device. Available in iOS 11 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowAirPrintCredentialsStorage',
    name: i18n.t('Disallow storage of AirPrint credentials in Keychain'),
    description: i18n.t(
      'Disables Keychain storage of usernames and passwords for AirPrint.' +
        ' Requires a supervised device. Available in iOS 11 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    dependencies: [{ key: 'allowAirPrint', mustBe: false }],
    isInverted: true,
  },
  {
    key: 'allowAirPrintiBeaconDiscovery',
    name: i18n.t('Disallow discovery of AirPrint printers using iBeacons'),
    description: i18n.t(
      'Disables iBeacon discovery of AirPrint printers, which prevents spurious' +
        ' AirPrint Bluetooth beacons from phishing for network traffic.' +
        ' Requires a supervised device. Available in iOS 11 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    dependencies: [{ key: 'allowAirPrint', mustBe: false }],
    isInverted: true,
  },
  {
    key: 'forceAirPrintTrustedTLSRequirement',
    name: i18n.t(
      'Disallow AirPrint to destinations with untrusted certificates',
    ),
    description: i18n.t(
      'Requires trusted certificates for TLS printing communication.' +
        ' Requires a supervised device. Available in iOS 11 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    dependencies: [{ key: 'allowAirPrint', mustBe: false }],
  },
];

export const appleWatchConfig = () => [
  {
    key: 'allowPairedWatch',
    name: i18n.t('Disallow pairing with Apple Watch'),
    description: i18n.t(
      'Disables pairing with an Apple Watch. Any currently paired Apple Watch is unpaired' +
        " and the watch's content is erased. Requires a supervised device. Available in iOS 9 and later.",
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'forceWatchWristDetection',
    name: i18n.t('Enforce Apple Watch wrist detection'),
    description: i18n.t(
      'Forces a paired Apple Watch to use Wrist Detection to lock it when the user takes it off.' +
        ' Available in iOS 8.2 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone],
  },
];

export const appsConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowAppInstallation',
      name: i18n.t('Disallow installing apps and updates'),
      description: getDescription().allowAppInstallation,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowUIAppInstallation',
      name: i18n.t('Disallow installing apps and updates from the device'),
      description: getDescription().allowUIAppInstallation,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      dependencies: [{ key: 'allowAppInstallation', mustBe: false }],
      isInverted: true,
    },
    {
      key: 'allowMarketplaceAppInstallation',
      name: i18n.t('Disallow marketplace apps and marketplace-hosted apps'),
      description: i18n.t(
        'Disallow installing marketplace apps and marketplace-hosted apps in the European Union.' +
          ' Requires a supervised device. Available in iOS 17.4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      dependencies: [{ key: 'allowAppInstallation', mustBe: false }],
      isInverted: true,
    },
    {
      key: 'allowWebDistributionAppInstallation',
      name: i18n.t('Disallow app installation directly from websites'),
      description: i18n.t(
        'Disallow installing apps directly from websites in the European Union.' +
          ' Requires a supervised device. Available in iOS 17.5 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowInAppPurchases',
      name: i18n.t('Disallow in-app purchases'),
      description: i18n.t(
        'In-app purchasing is prohibited. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowAutomaticAppDownloads',
      name: i18n.t('Disallow automatically downloading apps'),
      description: i18n.t(
        'Automatic downloading of apps purchased on other devices using the same iCloud account' +
          " is prohibited. This setting doesn't affect updates to existing apps." +
          ' Requires a supervised device. Available in iOS 9 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowAppRemoval',
      name: i18n.t('Disallow removing apps'),
      description: i18n.t(
        'Users are unable to remove apps from the device,' +
          ' including marketplace apps and marketplace-hosted apps in the European Union. ' +
          ' Requires a supervised device. Available in iOS 4.2.1 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowSystemAppRemoval',
      name: i18n.t('Disallow removing system apps'),
      description: getDescription().allowSystemAppRemoval,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      dependencies: [{ key: 'allowAppRemoval', mustBe: false }],
      isInverted: true,
    },
    {
      key: 'allowVideoConferencing',
      name: i18n.t('Disallow use of FaceTime'),
      description: getDescription().allowVideoConferencing,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowChat',
      name: i18n.t('Disallow use of Messages'),
      description: i18n.t(
        'On iPhone, the Messages app allows SMS text messaging, but not iMessage. ' +
          'On iPad, the Messages app is disabled, and its icon is removed from the Home screen. ' +
          'Requires a supervised device. Available in iOS 5 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowNews',
      name: i18n.t('Disallow use of News'),
      description: i18n.t(
        'The New app is disabled, and its icon is removed from the Home screen.' +
          ' Requires a supervised device. Available in iOS 9 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowPodcasts',
      name: i18n.t('Disallow use of Podcasts'),
      description: i18n.t(
        'The Podcasts app is disabled, and its icon is removed from the Home screen.' +
          ' Requires a supervised device. Available in iOS 8 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'ratingApps',
      name: i18n.t('Enforce app ratings'),
      description: i18n.t(
        'Manage the maximum level of app content allowed on the device.' +
          ' Apps that do not meet ratings compliance in the App Store will be unavailable for download.' +
          ' On iPhones and iPads (but not Macs), existing apps on the device that are out compliance will be' +
          ' disabled and removed from Home screen. Available in iOS 4 and later, macOS 10.15 and later,' +
          ' and tvOS 11.3 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone, tagKinds().tv],
      selectOptions: [
        {
          label: i18n.t('Allow All Apps'),
          value: 1000,
        },
        {
          label: '17+',
          value: 600,
        },
        {
          label: '12+',
          value: 300,
        },
        {
          label: '9+',
          value: 200,
        },
        {
          label: '4+',
          value: 100,
        },
        {
          label: i18n.t("Don't Allow Apps"),
          value: 0,
        },
      ],
      type: fieldTypes.select,
    },
    {
      key: 'allowAppClips',
      name: i18n.t('Disallow App Clips'),
      description: i18n.t(
        'Prevents a user from adding any App Clips, and removes any' +
          ' existing App Clips on the device. Available in iOS 14.0 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'forceDelayedAppSoftwareUpdates',
      name: i18n.t('Delay App Software Updates'),
      description: i18n.t(
        'Delays user visibility of non-OS Software Updates. Requires' +
          ' a supervised device. Available in macOS 11 and later.',
      ),
      tags: [tagKinds().mac, tagKinds().supervised],
    },
    {
      key: 'allowAppsToBeLocked',
      name: i18n.t('Disallow apps to be locked'),
      description: i18n.t(
        'Disallow apps to be locked in iOS and iPadOS 18 and later. Requires a supervised device. Note: Applying this restriction will also prevent apps from being able to be hidden.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      onAfterChange: (isChecked, _, update) =>
        update('allowAppsToBeHidden', (p) => ({ ...p, value: isChecked })),
    },
    {
      key: 'allowAppsToBeHidden',
      name: i18n.t('Disallow apps to be hidden'),
      description: i18n.t(
        'Disallow apps to be hidden in iOS and iPadOS 18 and later. Requires a supervised device.',
      ),
      dependencies: [{ key: 'allowAppsToBeLocked', mustBe: false }],
      hideDependencyText: true,
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    },
  ];
};

export const autonomousAppModeConfig = () => [
  {
    key: 'autonomousSingleAppModePermittedAppIDs',
    name: i18n.t('Autonomous Single App Mode'),
    description: i18n.t(
      'Allow listed apps to autonomously enter Single App Mode. Requires a supervised device.' +
        ' Available in iOS 7 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    type: fieldTypes.custom,
    component: BundleAppRow,
    componentProps: {},
    transformersToAPI: [extractValueArrayFromObject, removeIdFieldFromValue],
    transformersFromAPI: [
      interpolateArrayValueIntoCheckedObject,
      addIdFieldToValue,
    ],
  },
];

export const manageAppRestrictionsConfig = () => [
  {
    key: 'blacklistedAppBundleIDs',
    secondaryKeys: ['whitelistedAppBundleIDs'],
    name: i18n.t('Manage App Block List'),
    description: i18n.t(
      'Choose to block specific apps from being used, or specify that only listed apps are' +
        ' allowed. Restricted apps are disabled and hidden from the Home screen.' +
        ' This also applies to use of marketplace apps and marketplace-hosted apps in the European Union.' +
        ' Requires a supervised device. Available in iOS 9.3 and later, and tvOS 11.0 and later.',
    ),
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().tv,
      tagKinds().supervised,
    ],
    type: fieldTypes.custom,
    component: AppRestrictionsRow,
    componentProps: {},
    transformersToAPI: [transformToAppRestriction],
    transformersFromAPI: [
      interpolateArrayValueIntoCheckedObject,
      addIdFieldToValue,
      transformFromAppRestriction,
    ],
  },
];

export const cellularConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );
  return [
    {
      key: 'allowCellularPlanModification',
      name: i18n.t('Disallow modifying cellular plan settings'),
      description: i18n.t(
        'Prevents users from changing settings related to their cellular plan.' +
          ' Requires a supervised device. Available in iOS 11 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowLiveVoicemail',
      name: i18n.t('Disallow Live Voicemail'),
      description: i18n.t(
        'Disallow the use of Live Voicemail. Requires a supervised device.' +
          ' Available in iOS 17.2 and later.',
      ),
      tags: [tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowAppCellularDataModification',
      name: i18n.t('Disallow modifying cellular data app settings'),
      description: i18n.t(
        'Prevents users from changing settings for app cellular data usage. ' +
          'Requires a supervised device. Available in iOS 7 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowESIMModification',
      name: i18n.t('Disallow modifying eSIM settings'),
      description: i18n.t(
        'Users are prevented from making modifications to the eSIM setting.' +
          ' Requires a supervised device. Available in iOS 12.1 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowPersonalHotspotModification',
      name: i18n.t('Disallow modifying Personal Hotspot settings'),
      description: i18n.t(
        'Users are prevented from making modifications to the Personal Hotspot setting. ' +
          'Requires a supervised device. Available in iOS 12.2 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowGlobalBackgroundFetchWhenRoaming',
      name: i18n.t('Disallow global background fetch activity when roaming'),
      description: i18n.t(
        'Disables background fetch activity for apps when roaming. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowESIMOutgoingTransfers',
      name: i18n.t('Disallow eSIM outgoing transfers'),
      description: i18n.t(
        'Prevent the transfer of an eSIM from the device on which the restriction is installed to a different device. Requires a supervised device. Available in iOS and iPadOS 18 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'forcePreserveESIMOnErase',
      name: i18n.t('Force preserve eSIM on erase'),
      description: i18n.t(
        'Preserves eSIM when a device is erased due to too many failed password attempts or Erase All Content and Settings. The eSIM is not preserved if Find My initiates the erase. Requires a supervised device. Available in iOS and iPadOS 17.2 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowRCSMessaging',
      name: i18n.t('Disallow RCS messaging'),
      description: i18n.t(
        'Disallow the use of RCS messaging in Messages. Available in iOS and iPadOS 18.1 and later. Requires a supervised device.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowCallRecording',
      name: i18n.t('Disallow call recording'),
      description: i18n.t(
        'Disallow call recording. Available in iOS 18.1 and later. Requires a supervised device.',
      ),
      tags: [tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
  ];
};

export const dataSegregationConfig = () => [
  {
    key: 'allowOpenFromManagedToUnmanaged',
    name: i18n.t(
      'Disallow opening documents from managed sources in unmanaged destinations',
    ),
    description: getDescription().allowOpenFromManagedToUnmanaged,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
  {
    key: 'allowOpenFromUnmanagedToManaged',
    name: i18n.t(
      'Disallow opening documents from unmanaged sources in managed destinations',
    ),
    description: getDescription().allowOpenFromUnmanagedToManaged,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
  {
    key: 'allowManagedToWriteUnmanagedContacts',
    name: i18n.t(
      'Allow managed apps to write contacts to unmanaged contacts accounts',
    ),
    description: getDescription().allowManagedToWriteUnmanagedContacts,
    dependencies: [{ key: 'allowOpenFromManagedToUnmanaged', mustBe: false }],
    customDependencyText: i18n.t(
      'This option is not available if' +
        ' "Disallow opening documents from managed sources in unmanaged destinations" is enabled.',
    ),
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
  {
    key: 'allowUnmanagedToReadManagedContacts',
    name: i18n.t(
      'Allow unmanaged apps to read contacts from managed contacts accounts',
    ),
    description: getDescription().allowUnmanagedToReadManagedContacts,
    dependencies: [{ key: 'allowOpenFromManagedToUnmanaged', mustBe: false }],
    customDependencyText: i18n.t(
      'This option is not available if' +
        ' "Disallow opening documents from managed sources in unmanaged destinations" is enabled.',
    ),
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
];

export const diagnosticsConfig = () => [
  {
    key: 'allowDiagnosticSubmission',
    name: i18n.t('Disallow sending diagnostics and usage data to Apple'),
    description: getDescription().allowDiagnosticSubmission,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
  {
    key: 'allowDiagnosticSubmissionModification',
    name: i18n.t('Disallow modifying diagnostics settings'),
    description: getDescription().allowDiagnosticSubmissionModification,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
];

export const filesConfig = () => [
  {
    key: 'allowFilesNetworkDriveAccess',
    name: i18n.t('Disallow network drive access in Files app'),
    description: getDescription().allowFilesNetworkDriveAccess,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowFilesUSBDriveAccess',
    name: i18n.t('Disallow USB drive access in Files app'),
    description: i18n.t(
      'Prevents connecting to any connected USB devices in the Files app.' +
        ' Requires a supervised device. Available in iOS 13.0 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
];

export const findMyConfig = () => [
  {
    key: 'allowFindMyDevice',
    name: i18n.t('Disallow Find My Devices'),
    description: i18n.t(
      'Disables Find My Device in the Find My app. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 13 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowFindMyFriends',
    name: i18n.t('Disallow Find My Friends'),
    description: i18n.t(
      'Disables Find My Friends in the Find My app. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 13 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowFindMyFriendsModification',
    name: i18n.t('Disallow modifying Find My Friends settings'),
    description: i18n.t(
      'Disables changes to Find My Friends. Requires a supervised device. Available in iOS 7 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
];

export const gameCenterConfig = () => [
  {
    key: 'allowGameCenter',
    name: i18n.t('Disallow use of Game Center'),
    description: i18n.t(
      'Disables Game Center, and its icon is removed from the Home screen. ' +
        'iPhone and iPad devices must be supervised. Available in iOS 6 and later, and macOS 10.13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowAddingGameCenterFriends',
    name: i18n.t('Disallow adding Game Center friends'),
    description: i18n.t(
      'Prohibits adding friends to Game Center. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 4.2.1 and later, and macOS 10.13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    dependencies: [{ key: 'allowGameCenter', mustBe: false }],
    isInverted: true,
  },
  {
    key: 'allowMultiplayerGaming',
    name: i18n.t('Disallow multiplayer gaming'),
    description: i18n.t(
      'Prohibits multiplayer gaming. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 4.1 and later, and macOS 10.13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    dependencies: [{ key: 'allowGameCenter', mustBe: false }],
    isInverted: true,
  },
];

export const iCloudConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowCloudAddressBook',
      name: i18n.t('Disallow iCloud Address Book'),
      description: i18n.t(
        'Disables iCloud Address Book services. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudBookmarks',
      name: i18n.t('Disallow iCloud Bookmarks'),
      description: i18n.t(
        'Disables iCloud Bookmark sync. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudCalendar',
      name: i18n.t('Disallow iCloud Calendar'),
      description: i18n.t(
        'Disables iCloud Calendar services. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudDocumentSync',
      name: i18n.t('Disallow iCloud Drive'),
      description: getDescription().allowCloudDocumentSync,
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowCloudDesktopAndDocuments',
      name: i18n.t('Disallow iCloud Desktop & Documents'),
      description: i18n.t(
        'Disables syncing Desktop and Documents folders to iCloud. Available in macOS 10.12.4 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudKeychainSync',
      name: i18n.t('Disallow iCloud Keychain'),
      description: getDescription().allowCloudKeychainSync,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowCloudMail',
      name: i18n.t('Disallow iCloud Mail'),
      description: i18n.t(
        'Disables iCloud Mail services. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudNotes',
      name: i18n.t('Disallow iCloud Notes'),
      description: i18n.t(
        'Disables iCloud Notes services. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudReminders',
      name: i18n.t('Disallow iCloud Reminders'),
      description: i18n.t(
        'Disables iCloud Reminder services. Available in macOS 10.12 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowCloudPhotoLibrary',
      name: i18n.t('Disallow iCloud Photo Library'),
      description: getDescription().allowCloudPhotoLibrary,
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowPhotoStream',
      name: i18n.t('Disallow iCloud Photo Stream'),
      description: i18n.t(
        'Disables the iCloud Photo Stream. Available in iOS 5 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowSharedStream',
      name: i18n.t('Disallow iCloud Shared Photo Stream'),
      description: i18n.t(
        'Disables the iCloud Shared Photo Stream. Available in iOS 6 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowCloudBackup',
      name: i18n.t('Disallow device backup to iCloud'),
      description: getDescription().allowCloudBackup,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowManagedAppsCloudSync',
      name: i18n.t('Disallow managed app data storage in iCloud'),
      description: getDescription().allowManagedAppsCloudSync,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowActivityContinuation',
      name: i18n.t('Disallow Handoff'),
      description: getDescription().allowActivityContinuation,
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowCloudPrivateRelay',
      name: i18n.t('Disallow iCloud Private Relay'),
      description: getDescription().allowCloudPrivateRelay,
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
      legacyInversion: true,
    },
    {
      key: 'allowiPhoneWidgetsOnMac',
      name: i18n.t('Disallow iPhone Widgets on Mac'),
      description: i18n.t(
        'Prevents syncing of iPhone widgets on a Mac with iCloud. Requires a supervised device. Available in iOS 17 and later.',
      ),
      tags: [tagKinds().iphone, tagKinds().ipad, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowCloudFreeform',
      name: i18n.t('Disallow Freeform Document Syncing'),
      description: i18n.t(
        'Prevents a user from syncing Freeform documents with iCloud. Available in macOS 14 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
  ];
};

export const keyboardConfig = () => [
  {
    key: 'allowAutoCorrection',
    name: i18n.t('Disallow auto correction'),
    description: i18n.t(
      'Disables keyboard autocorrection. Requires a supervised device.' +
        ' Available in iOS 8.1.3 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowDefinitionLookup',
    name: i18n.t('Disallow definition lookup'),
    description: i18n.t(
      'Disables definition lookup. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 8.1.3 and later, and macOS 10.13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowDictation',
    name: i18n.t('Disallow dictation input'),
    description: i18n.t(
      'Disallows dictation input. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 10.3 and later, and macOS 10.13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowKeyboardShortcuts',
    name: i18n.t('Disallow keyboard shortcuts'),
    description: i18n.t(
      'Disables keyboard shortcuts. Requires a supervised device. Available in iOS 9 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowSpellCheck',
    name: i18n.t('Disallow spell check'),
    description: i18n.t(
      'Disables keyboard spell-check. Requires a supervised device. Available in iOS 8.1.3 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowPredictiveKeyboard',
    name: i18n.t('Disallow predictive keyboard'),
    description: i18n.t(
      'Disables predictive keyboards. Requires a supervised device. Available in iOS 8.1.3 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowContinuousPathKeyboard',
    name: i18n.t('Disallow Continuous Path keyboard'),
    description: i18n.t(
      'Disables QuickPath keyboard. Requires a supervised device. Available in iOS 13 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
];

export const lockScreenConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowVoiceDialing',
      name: i18n.t('Disallow voice dialing while device is locked'),
      description: i18n.t(
        'Disables voice dialing if the device is locked with a passcode. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowLockScreenControlCenter',
      name: i18n.t('Disallow Control Center on lock screen'),
      description: i18n.t(
        'Prevents the Control Center from appearing on the lock screen. Available in iOS 7 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowLockScreenNotificationsView',
      name: i18n.t('Disallow Notifications Center on lock screen'),
      description: i18n.t(
        'Prevents displaying notifications on the lock screen. Available in iOS 7 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowLockScreenTodayView',
      name: i18n.t('Disallow Today view on lock screen'),
      description: i18n.t(
        'Hides the Today view in the notification center on the lock screen. Available in iOS 7 and later.' +
          ' Also available for user enrollment.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowPassbookWhileLocked',
      name: i18n.t('Disallow Wallet notifications on lock screen'),
      description: i18n.t(
        'Prevents Wallet notifications on the lock screen. Available in iOS 6 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowUSBRestrictedMode',
      name: i18n.t('Allow USB accessories while device is locked'),
      description: i18n.t(
        'Allows the device to always connect to USB accessories while locked. On macOS,' +
          ' allows new USB accessories to connect without authorization. Requires a supervised device.' +
          ' Available in iOS 11.4.1 and later, and macOS 13 and later.',
      ),
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().supervised,
      ],
      isInverted: true,
    },
  ];
};

export const mediaConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowBookstore',
      name: i18n.t('Disallow use of Apple Books'),
      description: i18n.t(
        'Disables Apple Books. iPhone and iPad devices must be supervised. Available in iOS 6 and later.',
      ),
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowBookstoreErotica',
      name: i18n.t('Disallow explicit sexual content in Apple Books'),
      description: i18n.t(
        "Users can't download Apple Books media that is tagged as erotica." +
          ' Available in iOS 6 and later, macOS 10.15 and later, and tvOS 11.3 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone, tagKinds().tv],
      isInverted: true,
    },
    {
      key: 'allowEnterpriseBookBackup',
      name: i18n.t('Disallow backup of enterprise books'),
      description: i18n.t(
        'Disables backup of Enterprise books. Available in iOS 8 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowEnterpriseBookMetadataSync',
      name: i18n.t('Disallow notes and highlights sync of enterprise books'),
      description: i18n.t(
        'Disables sync of Enterprise books, notes, and highlights. Available in iOS 8 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowMusicService',
      name: i18n.t('Disallow use of Apple Music'),
      description: i18n.t(
        'Disables the Music service, and the Music app reverts to classic mode.' +
          ' iPhone and iPad devices must be supervised. Available in iOS 9.3 and later, and macOS 10.12 and later.',
      ),
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowRadioService',
      name: i18n.t('Disallow use of Apple Music Radio'),
      description: i18n.t(
        'Disables Apple Music Radio. Requires a supervised device. Available in iOS 9.3 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowiTunes',
      name: i18n.t('Disallow use of iTunes Store'),
      description: i18n.t(
        'Disables the iTunes Music Store, and its icon is removed from the Home screen.' +
          ' Users cannot preview, purchase, or download content. Requires a supervised device.' +
          ' Available in iOS 4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'forceITunesStorePasswordEntry',
      name: i18n.t('Require iTunes Store password for all purchases'),
      description: i18n.t(
        'Forces the user to enter their iTunes password for each transaction.' +
          ' Available in iOS 6 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowExplicitContent',
      name: i18n.t(
        'Disallow playback of explicit music, podcasts, and iTunes U media',
      ),
      description: i18n.t(
        'Hides explicit music or video content purchased from the iTunes Store.' +
          ' Explicit content is marked as such by content providers, such as record labels,' +
          ' when sold through the iTunes Store.' +
          ' iPhone and iPad devices must be supervised. Available in iOS 4 and later, macOS 10.15 and later,' +
          ' and tvOS 11.3 and later',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().tv,
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowiTunesFileSharing',
      name: i18n.t('Disallow iTunes file sharing services'),
      description: i18n.t(
        'Disables iTunes file sharing services. Available in macOS 10.13 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'ratingMovies',
      name: i18n.t('Manage Movie rating restrictions'),
      description: i18n.t(
        'Enforce the maximum level of movie content allowed on the device.' +
          ' Available in iOS 4 and later, macOS 10.15 and later, and tvOS 11.3 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone, tagKinds().tv],
      type: fieldTypes.select,
      selectOptions: [
        {
          label: i18n.t('Allow All Movies'),
          value: 1000,
        },
        {
          label: 'NC-17',
          value: 500,
        },
        {
          label: 'R',
          value: 400,
        },
        {
          label: 'PG-13',
          value: 300,
        },
        {
          label: 'PG',
          value: 200,
        },
        {
          label: 'G',
          value: 100,
        },
        {
          label: i18n.t("Don't Allow Movies"),
          value: 0,
        },
      ],
    },
    {
      key: 'ratingTVShows',
      name: i18n.t('Manage TV Show rating restrictions'),
      description: i18n.t(
        'Enforce the maximum level of TV content allowed on the device.' +
          ' Available in iOS 4 and later, macOS 10.15 and later, and tvOS 11.3 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone, tagKinds().tv],
      type: fieldTypes.select,
      selectOptions: [
        {
          label: i18n.t('Allow All TV Shows'),
          value: 1000,
        },
        {
          label: 'TV-MA',
          value: 600,
        },
        {
          label: 'TV-14',
          value: 500,
        },
        {
          label: 'TV-PG',
          value: 400,
        },
        {
          label: 'TV-G',
          value: 300,
        },
        {
          label: 'TV-Y7',
          value: 200,
        },
        {
          label: 'TV-Y',
          value: 100,
        },
        {
          label: i18n.t("Don't Allow TV Shows"),
          value: 0,
        },
      ],
    },
    {
      key: 'ratingRegion',
      isHidden: true,
      name: '',
      description: '',
      supervisionWarning: false,
      tags: [],
      type: fieldTypes.select,
      selectOptions: [
        {
          label: i18n.t('US'),
          value: 'us',
        },
      ],
    },
  ];
};

export const networkConfig = () => [
  {
    key: 'forceWiFiPowerOn',
    name: i18n.t('Force Wi-Fi power on'),
    description: i18n.t(
      'Prevents Wi-Fi from being turned off in Settings or Control Center, even by entering or leaving' +
        ' Airplane Mode. It does not prevent selecting which Wi-Fi network to use. Requires a supervised device.' +
        ' Available in iOS 13.0 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
  },
  {
    key: 'forceWiFiWhitelisting',
    name: i18n.t('Only allow managed Wi-Fi networks'),
    description: getDescription().forceWiFiWhitelisting,
    customWarning: i18n.t(
      'This option can prevent devices from connecting to the internet. Ensure at least one Wi-Fi profile is installed on every device.',
    ),
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
  },
  {
    key: 'allowBluetoothModification',
    name: i18n.t('Disallow modifying Bluetooth settings'),
    description: i18n.t(
      'Prevents the user from changing Bluetooth settings. Requires a supervised device.' +
        ' Available in iOS 11 and later, and macOS 13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowVPNCreation',
    name: i18n.t('Disallow adding VPN configurations'),
    description: getDescription().allowVPNCreation,
    tags: [
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowNFC',
    name: i18n.t('Disallow NFC'),
    description: i18n.t(
      'Disables Near Field Communication (NFC) on the device. Available in iOS 14.2 and later.',
    ),
    tags: [tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
    legacyInversion: true,
  },
];

export const osGeneralConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowUntrustedTLSPrompt',
      name: i18n.t('Automatically reject untrusted HTTPS certificates'),
      description: getDescription().allowUntrustedTLSPrompt,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'forceAutomaticDateAndTime',
      name: i18n.t('Force automatic date and time'),
      description: getDescription().forceAutomaticDateAndTime,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().tv,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
    },
    {
      key: 'allowRemoteAppPairing',
      name: i18n.t('Disallow Remote app pairing'),
      description: i18n.t(
        'Disables pairing Apple TV for use with the Remote app or Control Center widget.' +
          ' Requires a supervised device. Available in tvOS 10.2 and later.',
      ),
      tags: [tagKinds().tv, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowCamera',
      name: i18n.t('Disallow use of camera'),
      description: getDescription().allowCamera,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowHostPairing',
      name: i18n.t('Disallow pairing with non-Configurator hosts'),
      description: i18n.t(
        'Disables host pairing with the exception of the supervision host. If no supervision' +
          ' host certificate has been configured, all pairing is disabled. Requires a supervised device.' +
          ' Available in iOS 7 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowContentCaching',
      name: i18n.t('Disallow use of Content Caching service'),
      description: i18n.t(
        'Disables content caching. Available in macOS 10.13 and later.',
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowDeviceNameModification',
      name: i18n.t('Disallow modifying device name'),
      description: i18n.t(
        `Warning: This restriction preventing the device name from being changed has been moved to the Device Name Library Item.`,
      ),
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().tv,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowDeviceSleep',
      name: i18n.t('Disallow device sleeping'),
      description: i18n.t(
        'Prevents device from sleeping. Requires a supervised device. Available in tvOS 13 and later.',
      ),
      tags: [tagKinds().tv, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'allowEraseContentAndSettings',
      name: i18n.t('Disallow Erase All Content and Settings'),
      description: getDescription().allowEraseContentAndSettings,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
      legacyInversion: true,
    },
    {
      key: 'allowUIConfigurationProfileInstallation',
      name: i18n.t(
        'Disallow manual installation of configuration profiles and certificates',
      ),
      description: getDescription().allowUIConfigurationProfileInstallation,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowNotificationsModification',
      name: i18n.t('Disallow modifying notification settings'),
      description: getDescription().allowNotificationsModification,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowOTAPKIUpdates',
      name: i18n.t('Disallow OTA PKI updates'),
      description: i18n.t(
        'Disables over-the-air PKI updates. Setting this restriction' +
          " to false doesn't disable CRL and OCSP checks. Available in iOS 7 and later.",
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowEnablingRestrictions',
      name: i18n.t('Disallow use of Screen Time'),
      description: getDescription().allowEnablingRestrictions,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowScreenShot',
      name: i18n.t('Disallow screenshots and screen recording'),
      description: getDescription().allowScreenShot,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowSpotlightInternetResults',
      name: i18n.t('Disallow Spotlight internet search results'),
      description: i18n.t(
        'Disables Spotlight Internet search results.' +
          ' Available in iOS 8 and later, and macOS 10.11 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowEnterpriseAppTrust',
      name: i18n.t('Disallow "Trust Enterprise Developer" option'),
      description: getDescription().allowEnterpriseAppTrust,
      tags: [
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowWallpaperModification',
      name: i18n.t('Disallow modifying Wallpaper'),
      description: i18n.t(
        'Prevents wallpaper from being changed. iPhone and iPad devices must be supervised.' +
          ' Available in iOS 9 and later, and macOS 10.13 and later.',
      ),
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'forceLimitAdTracking',
      name: i18n.t('Force limited ad tracking'),
      description: i18n.t(
        "Limits ad tracking on Apple's ad platform. Additionally, it disables app tracking and the Allow Apps To Request To Track setting. Available in iOS 7 and later, and up through macOS 11.",
      ),
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone],
    },
    {
      key: 'forceEncryptedBackup',
      name: i18n.t('Force encrypted backups'),
      description: i18n.t(
        'Requires that all backups be encrypted. Available in iOS 4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
    },
    {
      key: 'allowSharedDeviceTemporarySession',
      name: i18n.t('Disallow guest user'),
      description: i18n.t(
        'Disallow the ability to log in as a Guest user on iPad devices configured as Shared iPad.' +
          ' Available in iOS 13.4 and later.',
      ),
      tags: [tagKinds().ipad],
      isInverted: true,
    },
    {
      key: 'allowUnpairedExternalBootToRecovery',
      name: i18n.t('Allow boot to recovery by unpaired device'),
      description: i18n.t(
        'Allows devices to be booted into recovery by an unpaired device.' +
          ' Requires a supervised device. Available in iOS 14.5 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    },
    {
      key: 'allowAutomaticScreenSaver',
      name: i18n.t('Disallow Apple TV automatic screensaver'),
      description: i18n.t(
        'Disables the Apple TV from automatically starting the screensaver after inactivity. Available in tvOS 15.4 and later.',
      ),
      tags: [tagKinds().tv],
      isInverted: true,
    },
    {
      key: 'allowUniversalControl',
      name: i18n.t('Disallow Universal Control'),
      description: i18n.t(
        "Disables the Universal Controls to allow the Mac's trackpad and keyboard to control additional Mac or iPad devices nearby. Available in macOS 13 and later.",
      ),
      tags: [tagKinds().mac],
      isInverted: true,
    },
    {
      key: 'allowApplePersonalizedAdvertising',
      name: i18n.t('Disallow Apple personalized advertisements'),
      description: getDescription().allowApplePersonalizedAdvertising,
      tags: [
        tagKinds().mac,
        tagKinds().iphone,
        tagKinds().ipad,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowStartupDiskModification',
      name: i18n.t('Disallow startup disk modification'),
      description: i18n.t(
        'Prevents the user from selecting a different startup disk. Available in macOS 14 and later.',
      ),
      tags: [tagKinds().mac],
    },

    {
      key: 'allowTimeMachineBackup',
      name: i18n.t('Disallow Time Machine backup'),
      description: i18n.t(
        'Prevents the user from setting up and using Time Machine. Available in macOS 14 and later.',
      ),
      tags: [tagKinds().mac],
    },

    {
      key: 'allowLocalUserCreation',
      name: i18n.t('Disallow local user creation'),
      description: i18n.t(
        'Prevents a user with the role of administrator from creating new users in Users & Groups in System Settings. Available in macOS 14 and later.',
      ),
      tags: [tagKinds().mac],
    },
    {
      key: 'allowMailPrivacyProtection',
      name: i18n.t('Disallow Mail Privacy Protection'),
      description: i18n.t(
        'Disables Mail Privacy Protection on the device. Requires a supervised device. Available in iOS 15.2 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'allowAutoDim',
      name: i18n.t('Disallow auto dim'),
      description: i18n.t(
        'Prevents auto dim on iPads with OLED displays. Available in iOS and iPadOS 17.4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'forceBypassScreenCaptureAlert',
      name: i18n.t('Bypass screen capture alerts'),
      description: i18n.t(
        'Prevents user notifications for content capture technologies in macOS 15.1 and later.',
      ),
      tags: [tagKinds().mac, tagKinds().supervised],
      isInverted: true,
    },
  ];
};

export const osUpdatesConfig = () => [
  {
    key: 'enforcedSoftwareUpdateDelay',
    name: i18n.t('Defer software updates'),
    description: i18n.t(
      'Delays user visibility of software updates. In macOS,' +
        ' seed build updates are allowed, without delay. iPhone and iPad devices must be supervised.' +
        ' Available in iOS 11.3 and later, macOS 10.13 and later, and tvOS 12.2 and later. ',
    ),
    descriptionLink: {
      link: 'https://support.apple.com/guide/deployment/manage-software-updates-depc4c80847a',
      text: i18n.t(
        'Learn more about Software Updates and their deferral limits.',
      ),
    },
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().tv,
      tagKinds().supervised,
    ],
    minimum: 1,
    maximum: 90,
    inputHelper: 'days',
    type: fieldTypes.number,
  },
  {
    key: 'allowRapidSecurityResponseInstallation',
    name: i18n.t('Disallow Rapid Security Response installation'),
    description: i18n.t(
      'Prevents installation of Apple Rapid Security responses. Devices must be supervised. Available in iOS 16.0 and later, iPadOS 16.0 and later and macOS 13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowRapidSecurityResponseRemoval',
    name: i18n.t('Disallow Rapid Security Response removal'),
    description: i18n.t(
      'Prevents removal of Apple Rapid Security responses. Devices must be supervised. Available in iOS 16.0 and later, iPadOS 16.0 and later and macOS 13 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
];

export const passcodeAuthConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowPasscodeModification',
      name: i18n.t('Disallow passcode modification'),
      description: getDescription().allowPasscodeModification,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowFingerprintModification',
      name: i18n.t('Disallow adding or removing Face ID / Touch ID data'),
      description: getDescription().allowFingerprintModification,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'allowFingerprintForUnlock',
      name: i18n.t('Disallow using Face ID / Touch ID for device unlock'),
      description: getDescription().allowFingerprintForUnlock,
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
      ],
      isInverted: true,
    },
    {
      key: 'allowAutoUnlock',
      name: i18n.t('Disallow using Apple Watch for device unlock'),
      description: i18n.t(
        'Prevents Apple Watch from being used to automatically unlock a Mac or iPhone.' +
          ' Available in macOS 10.12 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().mac, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'enforcedFingerprintTimeout',
      name: i18n.t('Enforce Fingerprint Timeout'),
      description: i18n.t(
        'The number of hours after which the fingerprint unlock will require a password' +
          ' to authenticate. Available in macOS 12 and later.',
      ),
      tags: [tagKinds().mac],
      helper: i18n.t('hours'),
      minimum: 1,
      maximum: 10000,
      type: fieldTypes.number,
    },
  ];
};

export const safariConfig = () => {
  const LDFF_restrictionSupervisionReqDep = featureFlags.getFlag(
    flags.lit08292023restrictionsupervisionreqdep,
  );

  return [
    {
      key: 'allowSafari',
      name: i18n.t('Disallow use of Safari'),
      description: i18n.t(
        'Disables the Safari web browser app, and its icon is removed from the Home screen.' +
          ' This setting also prevents users from opening web clips. Requires a supervised device.' +
          ' Available in iOS 4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
      isInverted: true,
    },
    {
      key: 'safariAllowAutoFill',
      name: i18n.t('Disallow Safari AutoFill'),
      description: getDescription().safariAllowAutoFill,
      tags: [
        tagKinds().mac,
        tagKinds().ipad,
        tagKinds().iphone,
        ...(featureFlags.getFlag(APPLE_VISION_PRO_FF)
          ? [tagKinds().vision]
          : []),
        tagKinds().supervised,
      ],
      isInverted: true,
    },
    {
      key: 'safariAllowPopups',
      name: i18n.t('Disallow Safari pop-ups'),
      description: i18n.t(
        'Disables pop-up windows in Safari. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'safariForceFraudWarning',
      name: i18n.t('Force Safari fraud warnings'),
      description: i18n.t(
        'Enables fraud warnings in Safari. Available in iOS 4 and later.',
      ),
      tags: [tagKinds().ipad, tagKinds().iphone],
    },
    {
      key: 'safariAllowJavaScript',
      name: i18n.t('Disallow Safari Javascript'),
      description: i18n.t(
        'Disables the use of Javascript in Safari. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      isInverted: true,
    },
    {
      key: 'safariAcceptCookies',
      name: i18n.t('Manage Safari cookies'),
      description: i18n.t(
        'Enforce cookie settings for Safari. Available in iOS 4 and later.',
      ),
      supervisionWarning: LDFF_restrictionSupervisionReqDep,
      tags: [tagKinds().ipad, tagKinds().iphone],
      type: fieldTypes.radio,
      radioOptions: [
        {
          label: i18n.t(
            'Force the option “Prevent Cross-Site Tracking” to be turned on',
          ),
          value: '1',
        },
        {
          label: i18n.t(
            'Force the options “Prevent Cross-Site Tracking” and “Block All Cookies” to be turned on',
          ),
          value: '0',
        },
      ],
      transformersToAPI: [transformValueStringToNumber],
      transformersFromAPI: [transformValueNumberToString],
    },
    {
      key: 'allowDeprecatedWebKitTLS',
      name: i18n.t('Allow deprecated TLS versions in Safari'),
      description: i18n.t(
        'Allow the use of deprecated TLS versions 1.0/1.1 in Safari. Available in iOS 12.4 and later,' +
          ' and macOS 10.15.4 and later.',
      ),
      tags: [tagKinds().mac, tagKinds().ipad, tagKinds().iphone],
    },
  ];
};

export const sharingConfig = () => [
  {
    key: 'allowFileSharingModification',
    name: i18n.t('Disallow File Sharing modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the File Sharing service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowPrinterSharingModification',
    name: i18n.t('Disallow Printer Sharing modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Printer Sharing service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowARDRemoteManagementModification',
    name: i18n.t('Disallow Remote Management modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Remote Management service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowRemoteAppleEventsModification',
    name: i18n.t('Disallow Remote Apple Events modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Remote Apple Events service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowInternetSharingModification',
    name: i18n.t('Disallow Internet Sharing modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Internet Sharing service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowBluetoothSharingModification',
    name: i18n.t('Disallow Bluetooth Sharing modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Bluetooth Sharing service in System Settings. Available in macOS 14 and later.',
    ),
    tags: [tagKinds().mac],
    isInverted: true,
  },
  {
    key: 'allowiPhoneMirroring',
    name: i18n.t('Disallow iPhone Mirroring'),
    description: i18n.t(
      'Prevents the use of iPhone Mirroring. When used on macOS, this prevents the Mac from mirroring any iPhone. When used on iOS, this prevents the iPhone from mirroring to any Mac. Requires a supervised device. Available in iOS 18 and later and macOS 15 and later.',
    ),
    tags: [tagKinds().mac, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowVideoConferencingRemoteControl',
    name: i18n.t('Disallow remote screen control via FaceTime'),
    description: i18n.t(
      'Prevents the ability for a remote FaceTime session to request control of the device. Requires a supervised device. Available in iOS and iPadOS 18 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowMediaSharingModification',
    name: i18n.t('Disallow Media Sharing modification'),
    description: i18n.t(
      'Prevents a user from changing the state of the Media Sharing service in System Settings. Available in macOS 15.1 and later.',
    ),
    tags: [tagKinds().mac, tagKinds().supervised],
    isInverted: true,
  },
];

export const siriConfig = () => [
  {
    key: 'allowAssistant',
    name: i18n.t('Disallow use of Siri'),
    description: getDescription().allowAssistant,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    isInverted: true,
  },
  {
    key: 'allowAssistantWhileLocked',
    name: i18n.t('Disallow use of Siri while device is locked'),
    description: i18n.t(
      "Disables Siri when the device is locked. This restriction is ignored if the device doesn't have a passcode set. Available in iOS 5.1 and later.",
    ),
    tags: [tagKinds().ipad, tagKinds().iphone],
    dependencies: [{ key: 'allowAssistant', mustBe: false }],
    isInverted: true,
  },
  {
    key: 'allowAssistantUserGeneratedContent',
    name: i18n.t('Disallow user-generated content in Siri'),
    description: i18n.t(
      'Prevents Siri from querying user-generated content from the web.' +
        ' Requires a supervised device. Available in iOS 7 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    dependencies: [{ key: 'allowAssistant', mustBe: false }],
    isInverted: true,
  },
  {
    key: 'forceAssistantProfanityFilter',
    name: i18n.t('Enable Siri profanity filter'),
    description: i18n.t(
      'Forces the use of the profanity filter with Siri.' +
        ' iPhone and iPad devices must be supervised. Available in iOS 11 and later, and macOS 10.14 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    dependencies: [{ key: 'allowAssistant', mustBe: false }],
  },
  {
    key: 'forceOnDeviceOnlyTranslation',
    name: i18n.t('Force on device translation'),
    description: i18n.t(
      'Prevent the device from connecting to the Siri servers for translations. Available in iOS 15, iPadOS 15, and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone],
    dependencies: [{ key: 'allowAssistant', mustBe: false }],
  },
  {
    key: 'forceOnDeviceOnlyDictation',
    name: i18n.t('Force on device dictation'),
    description: getDescription().forceOnDeviceOnlyDictation,
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      ...(featureFlags.getFlag(APPLE_VISION_PRO_FF) ? [tagKinds().vision] : []),
    ],
    dependencies: [{ key: 'allowAssistant', mustBe: false }],
  },
];

export const appleIntelligenceConfig = () => [
  {
    key: 'allowExternalIntelligenceIntegrations',
    name: i18n.t('Disallow external intelligence integrations'),
    description: i18n.t(
      'Prevents the use of external, cloud-based intelligence services with Siri. Cannot be used with Shared iPad. Available in iOS 18.2 and later and macOS 15.2 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowExternalIntelligenceIntegrationsSignIn',
    name: i18n.t('Disallow external intelligence integrations sign-in'),
    description: i18n.t(
      'Forces external intelligence providers into anonymous mode. If a user is already signed in to an external intelligence provider, applying this restriction will cause them to be signed out. Cannot be used with Shared iPad. Available in iOS 18.2 and later and macOS 15.2 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowedExternalIntelligenceWorkspaceIDs',
    name: i18n.t('Allowed external intelligence workspace IDs'),
    description: i18n.t(
      'When provided, only accounts in the specified workspace in the external intelligence system, such as ChatGPT, can sign in on the device. In ChatGPT, this ID can be found in the admin panel for your enterprise workspace.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    type: fieldTypes.custom,
    component: BundleAppRow,
    componentProps: {},
    transformersToAPI: [extractValueArrayFromObject, removeIdFieldFromValue],
    transformersFromAPI: [
      interpolateArrayValueIntoCheckedObject,
      addIdFieldToValue,
    ],
  },
  {
    key: 'allowGenmoji',
    name: i18n.t('Disallow Genmoji'),
    description: i18n.t(
      'Prohibits creating new Genmoji. Requires a supervised device. Available in iOS and iPadOS 18 and later, and macOS 15 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowImagePlayground',
    name: i18n.t('Disallow Image Playground'),
    description: i18n.t(
      'Prohibits the use of image generation. Requires a supervised device. Available in iOS and iPadOS 18 and later, and macOS 15 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowNotesTranscriptionSummary',
    name: i18n.t('Disallow Notes transcription summary'),
    description: i18n.t(
      'When checked, disallows transcription summaries in Notes',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowPersonalizedHandwritingResults',
    name: i18n.t('Disallow Personalized Handwriting Results'),
    description: i18n.t(
      "Prevents generating text in the user's handwriting. Requires a supervised device. Available in iOS and iPadOS 18 and later.",
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowImageWand',
    name: i18n.t('Disallow Image Wand'),
    description: i18n.t(
      'Prevent the use of Image Wand. Requires a supervised device. Available in iOS and iPadOS 18 and later.',
    ),
    tags: [tagKinds().ipad, tagKinds().iphone, tagKinds().supervised],
    isInverted: true,
  },
  {
    key: 'allowWritingTools',
    name: i18n.t('Disallow writing tools'),
    description: i18n.t(
      'Prevent the user from using Apple Intelligence writing tools.  Requires a supervised device. Available in iOS and iPadOS 18 and later, and macOS 15 and later.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
  {
    key: 'allowMailSummary',
    name: i18n.t('Disallow mail summaries'),
    description: i18n.t(
      'Disallow the use of the summarize button in Mail. Available in macOS 15.1 and later, and iOS and iPadOS 18.1 and later. Requires a supervised device.',
    ),
    tags: [
      tagKinds().mac,
      tagKinds().ipad,
      tagKinds().iphone,
      tagKinds().supervised,
    ],
    isInverted: true,
  },
];

/**
 * Responsible for mapping the categories to their respective configs.
 */
export const configMapping = (): {
  categoryMapping: Array<{
    category: string;
    config: Array<RestrictionConfig>;
    isHidden?: boolean;
  }>;
  keyToDescription: Record<string, RestrictionConfig>;
} => {
  const categories = getRestrictionCategories();

  const categoryMapping: Array<{
    category: string;
    config: Array<RestrictionConfig>;
    isHidden?: boolean;
  }> = [
    {
      category: categories.apps,
      config: appsConfig(),
    },
    {
      category: categories.blackandwhitelist,
      config: manageAppRestrictionsConfig(),
    },
    {
      category: categories.autonomoussingleappmode,
      config: autonomousAppModeConfig(),
    },
    {
      category: categories.passcodeandauthentication,
      config: passcodeAuthConfig(),
    },
    {
      category: categories.lockscreen,
      config: lockScreenConfig(),
    },
    {
      category: categories.accountsandpasswords,
      config: accountsAndPasswordsConfig(),
    },
    {
      category: categories.datasegregation,
      config: dataSegregationConfig(),
    },
    {
      category: categories.icloud,
      config: iCloudConfig(),
    },
    {
      category: categories.network,
      config: networkConfig(),
    },
    {
      category: categories.cellular,
      config: cellularConfig(),
    },
    {
      category: categories.airdrop,
      config: airdropConfig(),
    },
    {
      category: categories.airplay,
      config: airplayConfig(),
    },
    {
      category: categories.airprint,
      config: airprintConfig(),
    },
    {
      category: categories.applewatch,
      config: appleWatchConfig(),
    },
    {
      category: categories.diagnostics,
      config: diagnosticsConfig(),
    },
    {
      category: categories.files,
      config: filesConfig(),
    },
    {
      category: categories.findmy,
      config: findMyConfig(),
    },
    {
      category: categories.gamecenter,
      config: gameCenterConfig(),
    },
    {
      category: categories.keyboard,
      config: keyboardConfig(),
    },
    {
      category: categories.media,
      config: mediaConfig(),
    },
    {
      category: categories.osupdates,
      config: osUpdatesConfig(),
    },
    {
      category: categories.safari,
      config: safariConfig(),
    },
    {
      category: categories.siri,
      config: siriConfig(),
    },
    {
      category: categories.osandgeneral,
      config: osGeneralConfig(),
    },
    {
      category: categories.sharing,
      config: sharingConfig(),
    },
    {
      category: categories.appleIntelligence,
      config: appleIntelligenceConfig(),
    },
  ];

  // Compiles an object for mapping keys to its configuration.
  const keyToDescription = categoryMapping.reduce(
    (
      a,
      c: {
        category: string;
        config: Array<RestrictionConfig>;
        isHidden?: boolean;
      },
    ) => {
      const config = c.config.reduce(
        (aa, cc) => ({
          ...aa,
          [cc.key]: cc,
          ...cc.secondaryKeys?.reduce(
            (sAcc, sKey) => ({ ...sAcc, [sKey]: cc }),
            {},
          ),
        }),
        {},
      );

      return {
        ...a,
        ...config,
      };
    },
    {},
  );

  return { categoryMapping, keyToDescription };
};
