import {
  type ValidationFunction,
  ValidationTemplates,
} from '@kandji-inc/nectar-form';
import type { FacetData } from 'features/rules-modal/types';
import { DATA_TYPES, TEXT_TYPES } from 'features/rules/constants';

export const convertFacetMapValidationToNectarFormValidation = (
  validation: Pick<FacetData, 'validation'>,
): ValidationFunction[] => {
  // convert validation
  const NFValidation = [];
  for (const key in validation) {
    // some entries have null validation under a key
    if (validation[key] === null) continue;
    if (key === 'character_limit') {
      const { value, error_message } = validation[key];
      NFValidation.push(
        ValidationTemplates.maxLength(error_message)(Number(value)),
      );
    } else if (key === 'regex') {
      const { value, error_message } = validation[key];
      NFValidation.push(ValidationTemplates.pattern(error_message)(value));
    } else {
      throw Error(`Unsupported validation key: ${key}`);
    }
  }
  return NFValidation;
};

export const operatorHasMultipleValues = (
  dataType: string,
  operator: string,
): boolean => {
  if (
    dataType === DATA_TYPES.MULTISELECT ||
    dataType === DATA_TYPES.MULTISELECTAPI ||
    operator === TEXT_TYPES.BETWEEN
  ) {
    return true;
  }
  if (dataType === DATA_TYPES.MULTITEXT) {
    return ![
      TEXT_TYPES.CONTAINS,
      TEXT_TYPES.DOES_NOT_CONTAIN,
      TEXT_TYPES.IS,
      TEXT_TYPES.IS_NOT,
    ].includes(operator);
  }
  return false;
};

export const getDeviceFamilyIconByOS = (deviceFamily: string): string => {
  switch (deviceFamily?.toLowerCase()) {
    case 'macos':
      return 'sf-desktop-computer';
    case 'ios':
      return 'sf-iphone';
    case 'ipados':
      return 'sf-ipad-landscape';
    case 'tvos':
      return 'sf-apple-tv';
    case 'visionos':
      return 'sf-apple-vision-pro';
  }
};
