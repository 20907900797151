import { i18n } from 'src/i18n';
import uuid from 'uuid/v4';

const COMMA = ', ';
const AND = ' and ';

export const CONCATENATION = {
  between: AND,
  in: COMMA,
  '!in': COMMA,
  contains_any: COMMA,
  '!contains_any': COMMA,
  '==': COMMA,
  '!=': COMMA,
};

export const DATA_TYPES = {
  MULTISELECT: 'multi-select',
  MULTISELECTAPI: 'multi-select-api',
  MULTITEXT: 'multi-text',
  SELECT: 'select',
  TEXT: 'text',
};

export const TEXT_TYPES = {
  BETWEEN: 'between',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: '!contains',
  IS: '==',
  IS_NOT: '!=',
  IS_BLANK: '!',
  IS_NOT_BLANK: '!!',
  IS_ANY: '!!',
};

export const FACET_GROUPS = () => ({
  computer: {
    label: i18n.t('Device criteria'),
    banner: '',
  },
  user_directory_integration: {
    label: i18n.t('User directory criteria'),
    banner: i18n.t(
      'A supported directory integration is required to add a rule from user directory criteria.',
    ),
  },
});

export const LI_DEVICE_SPECIFIC_OS_VERSION_FACETS = [
  'os_version',
  'ios_version',
  'ipados_version',
  'tvos_version',
];

export const AM_DEVICE_SPECIFIC_OS_VERSION_FACETS = [
  'macos',
  'ios',
  'ipados',
  'tvos',
  'visionos',
];

export const OS_VERSION_PARENT_KEY = 'parent_os_version';
export const AM_OS_VERSION_PARENT_KEY = 'am_parent_os_version';

export const LI_OS_VERSION_DEFAULT_OPERATOR = 'is any';

export const LI_OS_VERSION_RULE_TEMPLATE = {
  key: uuid(),
  subject: 'computer',
  input: OS_VERSION_PARENT_KEY,
  children: {
    os_version: { operator: LI_OS_VERSION_DEFAULT_OPERATOR, value: '' },
    ios_version: { operator: LI_OS_VERSION_DEFAULT_OPERATOR, value: '' },
    ipados_version: { operator: LI_OS_VERSION_DEFAULT_OPERATOR, value: '' },
    tvos_version: { operator: LI_OS_VERSION_DEFAULT_OPERATOR, value: '' },
    visionos_version: { operator: LI_OS_VERSION_DEFAULT_OPERATOR, value: '' },
  },
};

export const AM_OS_VERSION_RULE_TEMPLATE = {
  key: uuid(),
  subject: 'computer',
  input: AM_OS_VERSION_PARENT_KEY,
  children: [],
};
