import { type CSS, Flex, styled } from '@kandji-inc/nectar-ui';

type AppIconProps = {
  src: string;
  alt?: string;
  size?: 'sm' | 'md' | 'lg';
  css?: CSS;
};

const AppIconContainer = styled(Flex, {
  variants: {
    size: {
      sm: {
        width: '$5',
        height: '$5',
      },
      md: {
        width: '$6',
        height: '$6',
      },
      lg: {
        width: '$7',
        height: '$7',
      },
    },
  },
});

const AppIcon = (props: AppIconProps) => {
  const { src, alt = '', size = 'sm', css } = props;

  if (!src) {
    return null;
  }

  return (
    <AppIconContainer
      justifyContent="center"
      alignItems="center"
      size={size}
      css={{
        '& img': {
          width: '100%',
          height: '100%',
        },
        ...css,
      }}
    >
      <img src={src} alt={alt} />
    </AppIconContainer>
  );
};

export { AppIcon };
