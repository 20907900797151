import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { i18n } from 'src/i18n';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getCellularColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const cellularSchema = schemas.find((schema) => schema.uri === 'cellular');

  if (!cellularSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...cellularSchema.schema.properties,
  };

  const cellularColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    cellularColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    cellularColumnHelper.accessor(
      (row) => row.carrier_settings_version_slot_1,
      {
        id: 'carrier_settings_version_slot_1',
        meta: {
          displayName: combinedProperties.carrier_settings_version_slot_1.title,
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    cellularColumnHelper.accessor(
      (row) => row.carrier_settings_version_slot_2,
      {
        id: 'carrier_settings_version_slot_2',
        meta: {
          displayName: combinedProperties.carrier_settings_version_slot_2.title,
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    cellularColumnHelper.accessor((row) => row.cellular_technology, {
      id: 'cellular_technology',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone']),
      meta: {
        displayName: combinedProperties.cellular_technology.title,
        defaultHidden: true,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'None', label: i18n.t('None') },
          { value: 'GSM', label: i18n.t('GSM') },
          { value: 'CDMA', label: i18n.t('CDMA') },
          { value: 'GSM and CDMA', label: i18n.t('Both GSM and CDMA') },
        ],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_carrier_network_slot_1, {
      id: 'current_carrier_network_slot_1',
      meta: {
        displayName: combinedProperties.current_carrier_network_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_carrier_network_slot_2, {
      id: 'current_carrier_network_slot_2',
      meta: {
        displayName: combinedProperties.current_carrier_network_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_mcc_slot_1, {
      id: 'current_mcc_slot_1',
      meta: {
        displayName: combinedProperties.current_mcc_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_mcc_slot_2, {
      id: 'current_mcc_slot_2',
      meta: {
        displayName: combinedProperties.current_mcc_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_mnc_slot_1, {
      id: 'current_mnc_slot_1',
      meta: {
        displayName: combinedProperties.current_mnc_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.current_mnc_slot_2, {
      id: 'current_mnc_slot_2',
      meta: {
        displayName: combinedProperties.current_mnc_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.eid_slot_1, {
      id: 'eid_slot_1',
      meta: {
        displayName: combinedProperties.eid_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.eid_slot_2, {
      id: 'eid_slot_2',
      meta: {
        displayName: combinedProperties.eid_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.iccid_slot_1, {
      id: 'iccid_slot_1',
      meta: {
        displayName: combinedProperties.iccid_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.iccid_slot_2, {
      id: 'iccid_slot_2',
      meta: {
        displayName: combinedProperties.iccid_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.phone_number_slot_1, {
      id: 'phone_number_slot_1',
      meta: {
        displayName: combinedProperties.phone_number_slot_1.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.phone_number_slot_2, {
      id: 'phone_number_slot_2',
      meta: {
        displayName: combinedProperties.phone_number_slot_2.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.imei_slot_1, {
      id: 'imei_slot_1',
      meta: {
        displayName: combinedProperties.imei_slot_1.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.imei_slot_2, {
      id: 'imei_slot_2',
      meta: {
        displayName: combinedProperties.imei_slot_2.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.meid, {
      id: 'meid',
      meta: {
        displayName: combinedProperties.meid.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_data_preferred_slot_1, {
      id: 'is_data_preferred_slot_1',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_data_preferred_slot_1.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_data_preferred_slot_2, {
      id: 'is_data_preferred_slot_2',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_data_preferred_slot_2.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_roaming_slot_1, {
      id: 'is_roaming_slot_1',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_roaming_slot_1.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_roaming_slot_2, {
      id: 'is_roaming_slot_2',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_roaming_slot_2.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.data_roaming_enabled, {
      id: 'data_roaming_enabled',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.data_roaming_enabled.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_voice_preferred_slot_1, {
      id: 'is_voice_preferred_slot_1',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_voice_preferred_slot_1.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.is_voice_preferred_slot_2, {
      id: 'is_voice_preferred_slot_2',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_voice_preferred_slot_2.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.voice_roaming_enabled, {
      id: 'voice_roaming_enabled',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.voice_roaming_enabled.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.label_id_slot_1, {
      id: 'label_id_slot_1',
      meta: {
        displayName: combinedProperties.label_id_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.label_id_slot_2, {
      id: 'label_id_slot_2',
      meta: {
        displayName: combinedProperties.label_id_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.label_slot_1, {
      id: 'label_slot_1',
      meta: {
        displayName: combinedProperties.label_slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.label_slot_2, {
      id: 'label_slot_2',
      meta: {
        displayName: combinedProperties.label_slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.modem_firmware_version, {
      id: 'modem_firmware_version',
      meta: {
        displayName: combinedProperties.modem_firmware_version.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.personal_hotspot_enabled, {
      id: 'personal_hotspot_enabled',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.personal_hotspot_enabled.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.slot_1, {
      id: 'slot_1',
      meta: {
        displayName: combinedProperties.slot_1.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor((row) => row.slot_2, {
      id: 'slot_2',
      meta: {
        displayName: combinedProperties.slot_2.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    cellularColumnHelper.accessor(
      (row) => row.subscriber_carrier_network_slot_1,
      {
        id: 'subscriber_carrier_network_slot_1',
        meta: {
          displayName:
            combinedProperties.subscriber_carrier_network_slot_1.title,
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    cellularColumnHelper.accessor(
      (row) => row.subscriber_carrier_network_slot_2,
      {
        id: 'subscriber_carrier_network_slot_2',
        meta: {
          displayName:
            combinedProperties.subscriber_carrier_network_slot_2.title,
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
