/* istanbul ignore file */
import type { DetectionDateFilterFields } from '../common/common.types';

const getTextWidth = (text: string, element: HTMLElement = document.body) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the computed style of the element and set it on the canvas context
  const style = window.getComputedStyle(element);
  context.font = `${style.fontSize} ${style.fontFamily}`;

  return context.measureText(text).width;
};

export const displayAndTruncateList = (
  strings: string[],
  maxWidth: number = 530,
  separator = ', ',
  element = document.body,
) => {
  const displayed = [];
  let hidden = [];
  let totalWidth = 0;

  for (let i = 0; i < strings.length; i++) {
    const iconWidth = 20;
    const string = strings[i];
    // TODO: this assumes every app has an icon. can we make this dynamic?
    const stringWidth = getTextWidth(string, element) + iconWidth;
    const separatorWidth = i > 0 ? getTextWidth(separator, element) : 0;

    if (totalWidth + stringWidth + separatorWidth > maxWidth) {
      hidden = strings.slice(i); // All remaining strings are hidden
      break;
    }

    displayed.push(string);
    totalWidth += stringWidth + separatorWidth;
  }

  return { displayed, hidden };
};

const addDay = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

// Turns relative dates (ex. "24hours", "7days") into API format
export const transformDaysAgoFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value } = dateFilter;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (value === '24hours') {
    return { days_ago: 1, timezone };
  }

  // Retrive the number of days from "7days", "30days", etc. and convert to a number
  return { days_ago: parseInt(value.replace('days', ''), 10), timezone };
};

export const transformDetectionDateFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value, operator, dateRangeFrom, dateRangeTo, selectedDate } =
    dateFilter;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!value || value === 'all_time') {
    return null;
  }

  /* istanbul ignore if */
  if (operator) {
    if (operator === 'ib') {
      return {
        gte: dateRangeFrom,
        lt: addDay(dateRangeTo),
      };
    }

    if (operator === 'eq') {
      return {
        gte: selectedDate,
        lt: addDay(selectedDate),
      };
    }

    if (operator === 'lte') {
      return {
        lt: addDay(selectedDate),
      };
    }

    return { [operator]: selectedDate };
  }

  return { relative: { value, timezone } };
};
