import { Box, Icon, Th, Thead, Tr, css, styled } from '@kandji-inc/nectar-ui';
import { Fragment, useState } from 'react';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';
import { constants } from '../../../common';
import type {
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
} from '../../../threat.types';
import ThreatListTableCheckbox from './ThreatListTableCheckbox';

const TheadStyled = styled(Thead, {
  position: 'sticky',
  zIndex: 0,
});

const getNextSortOrder = (
  sortOrder: SortColumnDirection | undefined,
  sortBy: SortColumnName | undefined,
  targetColumn: SortColumnName,
) => {
  const orders: SortColumnDirection[] = constants.THREAT_SORT_ORDER;
  // Sets the next in line sort order
  const nextDirection: SortColumnDirection | undefined =
    targetColumn === sortBy && sortOrder
      ? orders[(orders.indexOf(sortOrder) + 1) % orders.length]
      : 'asc';

  return nextDirection;
};

type ThreatListTableHeadProps = Readonly<{
  sort: SortColumnState;
  onColumnSort: (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => void;
  isSortingDisabled: boolean;
  columns: TableHeaderColumn[];
  isSelectable: boolean;
  onAllSelected: ((isSelected: boolean) => void) | undefined;
  isAllSelected: boolean;
  isSelectionDisabled: boolean;
  isMoreActionsShown?: boolean;
  isFirstHeaderColumnShown?: boolean;
}>;

const infoIconStyle = css({
  pl: '$1',
  '& > svg': { height: 16, width: 16, verticalAlign: 'text-top' },
});

const ThreatListTableHead = (props: ThreatListTableHeadProps) => {
  const {
    sort: { sortBy, sortOrder },
    onColumnSort,
    isSortingDisabled,
    columns,
    isSelectable,
    onAllSelected,
    isAllSelected,
    isSelectionDisabled,
    isMoreActionsShown,
    isFirstHeaderColumnShown,
  } = props;

  const [isThHovered, setIsThHovered] = useState<boolean>(false);

  const handleHeaderClick = (newColumn: SortColumnName) => () => {
    const nextDirection = getNextSortOrder(sortOrder, sortBy, newColumn);
    // If sortOrder is 'none' we reset sortBy and sortOrder to undefined
    const sortByColumn = nextDirection === 'none' ? undefined : newColumn;
    const direction = nextDirection === 'none' ? undefined : nextDirection;
    onColumnSort(sortByColumn, direction);
  };

  const applySort = (newColumn: SortColumnName) => ({
    state: sortBy === newColumn ? sortOrder : 'none',
    onSort: !isSortingDisabled ? handleHeaderClick(newColumn) : null,
  });

  return (
    <TheadStyled>
      <Tr>
        {!isSelectable && isFirstHeaderColumnShown && (
          <Th title="" css={{ $$cellWidth: '$sizes-6' }} />
        )}
        <>
          {columns.map((column, index) => (
            <Fragment key={`${column.name}${column.title}`}>
              {!column.isHidden && (
                <Th
                  sort={
                    column.isSortable === false || (isThHovered && index === 0)
                      ? null
                      : applySort(column.name)
                  }
                  css={{
                    paddingBlock: 0,
                    $$cellWidth: `$sizes-${column.size}`,
                    '& span': { display: 'flex', alignItems: 'center' },
                    '& > div > div': { alignItems: 'center' },
                  }}
                >
                  {isSelectable && index === 0 && (
                    <Box title={isSelectable ? 'Select' : 'Expand'}>
                      <ThreatListTableCheckbox
                        selected={isAllSelected}
                        onToggle={
                          // istanbul ignore next
                          (isSelected) => {
                            if (typeof onAllSelected === 'function') {
                              onAllSelected(isSelected);
                            }
                          }
                        }
                        onHoverToggle={setIsThHovered}
                        disabled={isSelectionDisabled}
                        testId="select-row-button"
                      />
                    </Box>
                  )}
                  <Box
                    title={column.title}
                    css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {column.title}
                  </Box>
                  {column.tooltip && (
                    <EDRTooltip
                      label={column.tooltip}
                      css={{ maxWidth: column.tooltipWidth || undefined }}
                    >
                      <span className={infoIconStyle().className}>
                        <Icon name="circle-info" />
                      </span>
                    </EDRTooltip>
                  )}
                </Th>
              )}
            </Fragment>
          ))}
        </>
        {isMoreActionsShown && (
          <Th title="Actions" css={{ $$cellWidth: '$sizes-8' }}>
            &nbsp;
          </Th>
        )}
      </Tr>
    </TheadStyled>
  );
};

export default ThreatListTableHead;
