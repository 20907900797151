import { formatNumber } from '@kandji-inc/nectar-i18n';
import { Flex, Heading, Icon, Loader, Text } from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router';
import { i18n } from 'src/i18n';
import { useGetCVEImpactingTheMostDevices } from '../../../hooks/dashboard/use-get-cve-impacting-the-most-devices';
import useVulnerability from '../../../store';
import { Tile, TileHeading } from './tile';

const CVEImpactingTheMostDevicesTile = () => {
  const { cveId, count, isLoading } = useGetCVEImpactingTheMostDevices();

  const setSelectedVulnerabilityId = useVulnerability(
    (state) => state.setSelectedVulnerabilityId,
  );
  const history = useHistory();

  const hasData = !isLoading && cveId;

  const onTileClick = /* istanbul ignore next */ (cveId) => {
    setSelectedVulnerabilityId(cveId);

    history.push({
      pathname: history.location.pathname,
      search: `?cve_id=${cveId}`,
    });
  };

  const interactiveProps = {
    interactive: true,
    hoverVariant: 'elevate-1',
    onClick: () => onTileClick(cveId),
    css: { '&:hover': { cursor: 'pointer' }, minWidth: 0 },
  };

  return (
    <Tile {...(hasData ? interactiveProps : { css: { minWidth: 0 } })}>
      <Flex hFull alignItems="center" justifyContent="space-between">
        <Flex flow="column" gap="xs" css={{ minWidth: 0 }}>
          <Flex alignItems="center" gap="xs">
            <Icon
              name="bug"
              size="md"
              style={{ transform: 'rotate(180deg)', minWidth: 24 }}
            />
            <TileHeading size="4">
              {`${i18n.t('Impacting the most devices')}${hasData ? `: ${cveId}` : ''}`}
            </TileHeading>
          </Flex>

          {isLoading && (
            <Flex hFull alignItems="center" justifyContent="center">
              <Loader data-testid="loading-cve-impacting-the-most-devices" />
            </Flex>
          )}

          {hasData && (
            <Flex flow="column">
              <Heading size="2">{formatNumber(count)}</Heading>
              <Text variant="description">
                {count === 1 ? i18n.t('Device') : i18n.t('Devices')}
              </Text>
            </Flex>
          )}

          {!isLoading && !cveId && (
            <Flex hFull wFull alignItems="center">
              <Text>
                {i18n.t(
                  'No vulnerabilities have been detected in your Mac fleet.',
                )}
              </Text>
            </Flex>
          )}
        </Flex>
        {hasData && <Icon name="fa-angle-right-small" size="md" />}
      </Flex>
    </Tile>
  );
};

export { CVEImpactingTheMostDevicesTile };
