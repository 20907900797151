import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Box,
  Flex,
  Heading,
  Loader,
  ProgressChips,
  Text,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import { i18n } from 'src/i18n';
import { SeverityProgressChips } from '../../../components/severity-progress-chips';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from '../../../constants';
import { DEFAULT_DETECTION_DATE_FILTER } from '../../../constants';
import { useGetVulnerabilitiesBySeverity } from '../../../hooks/dashboard/use-get-vulnerabilities-by-severity';
import { Tile, TileHeading } from './tile';

const VulnerabilitiesBySeverityTile = () => {
  const [timespan, setTimespan] = useState<Partial<DetectionDateFilterFields>>(
    DEFAULT_DETECTION_DATE_FILTER,
  );
  const { numCritical, numHigh, numMedium, numLow, total, isLoading } =
    useGetVulnerabilitiesBySeverity(timespan);

  const filledPieData = [
    {
      name: i18n.t('Critical'),
      value: numCritical,
      color: 'var(--colors-red80)',
    },
    {
      name: i18n.t('High'),
      value: numHigh,
      color: 'var(--colors-red50)',
    },
    {
      name: i18n.t('Medium'),
      value: numMedium,
      color: 'var(--colors-yellow60)',
    },
    {
      name: i18n.t('Low'),
      value: numLow,
      color: 'var(--colors-yellow50)',
    },
  ];

  const emptyPieData = [
    { name: 'blank', value: 1, color: 'var(--colors-neutral40)' },
  ];

  const pieData = total > 0 ? filledPieData : emptyPieData;

  const radius = 70;

  return (
    <Tile>
      <Flex flow="column" gap="xs" hFull>
        <Flex alignItems="center" justifyContent="space-between" wFull>
          <TileHeading size="4">
            {i18n.t('Vulnerabilities by severity')}
          </TileHeading>
          <EDRDetectionDateFilter
            filter={timespan}
            label=""
            options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.filter(
              (option) => option.value !== 'all_time',
            )}
            onChange={(_, fields: DetectionDateFilterFields) =>
              setTimespan(fields)
            }
            showClear={false}
            dropdownProps={{ contentProps: { align: 'end' } }}
            buttonProps={{ disabled: isLoading }}
            buttonVariant="subtle"
          />
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-vulnerabilities-by-severity" />
          </Flex>
        )}

        {!isLoading && (
          <Flex
            alignItems="center"
            hFull
            css={{ justifyContent: 'space-around' }}
          >
            <Box css={{ position: 'relative' }}>
              <PieChart
                width={140}
                height={140}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Pie
                  dataKey="value"
                  data={pieData}
                  cx={radius}
                  cy={radius}
                  startAngle={540}
                  endAngle={180}
                  innerRadius={50}
                  outerRadius={63}
                  paddingAngle={1}
                  animationBegin={0}
                  animationDuration={800}
                >
                  {pieData.map((item) => (
                    <Cell key={item.name} fill={item.color} />
                  ))}
                </Pie>
              </PieChart>
              <Heading
                size="4"
                css={{
                  top: radius - 10,
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {formatNumber(total)}
              </Heading>
            </Box>
            <Flex flow="column" gap="sm">
              {filledPieData.map((item) => (
                <Flex alignItems="center">
                  <ProgressChips
                    length={1}
                    filledLength={1}
                    filledColor={item.color}
                  />
                  <Text
                    css={{ color: 'var(--colors-neutral70)' }}
                  >{`${item.name} - ${formatNumber(item.value)}`}</Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { VulnerabilitiesBySeverityTile };
