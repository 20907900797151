import { updateSetting as update } from '@kandji-inc/bumblebee';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import LibraryContext from 'features/library-items/routes/library.context';
/* istanbul ignore file */
import React, { useCallback, useContext } from 'react';
import featureFlags from 'src/config/feature-flags';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { deviceTypes } from '../../library/common';
import { useValidateFileInput } from './hooks/useValidateFileInput';
import { InHouseAppDescription } from './in-house-apps-description';
import type { IpaFile } from './in-house-apps.types';
import createInitialState from './initial-state';
import { InstallDetails } from './sections/install-details';
import { createTransformToApi, transformFromApi } from './service/transformers';
import useInHouseAppsService, {
  inHouseAppsService,
} from './service/use-in-house-apps.service';

function InHouseAppComponent({ pageProps }) {
  const { itemConfig } = useContext(LibraryContext);
  const blueprintConflicts = useBlueprintConflicts();
  const [shouldUpdateFile, setShouldUpdateFile] = React.useState(false);
  const [_, triggerValidation] = useUniqValue();

  const { model, setModel, pageState, handlers } = pageProps;

  const updateInstallDetails = useCallback(
    update('installDetails', setModel),
    [],
  );

  function updateIpaFile(updateBody: Partial<IpaFile>) {
    updateInstallDetails('ipaFile', (previous: IpaFile) => ({
      ...previous,
      ...updateBody,
    }));
  }

  const { ipaFile } = model.installDetails;
  const setIsSaveEnabled = handlers.onValidate((enabled: boolean) => enabled);

  useValidateFileInput(
    model,
    setIsSaveEnabled,
    pageState.isValid,
    pageState.isEditing,
  );

  return (
    <LibraryItemPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="ipa-apps-v2"
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: (
          <InHouseAppDescription
            description={itemConfig.getDescription()}
            appName={ipaFile.appName}
            appVersion={ipaFile.appVersion}
          />
        ),
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={createTransformToApi({ shouldUpdateFile })}
      transformFromApi={transformFromApi}
      service={inHouseAppsService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules={false}
      supportsDuplication
      supportsInstallOn
    >
      <InstallDetails
        isDisabled={pageState.isDisabled}
        model={model}
        updateIpaFile={updateIpaFile}
        onValidate={handlers.onValidate}
        isSubmitted={pageState.isSubmitted}
        setShouldUpdateFile={setShouldUpdateFile}
      />
    </LibraryItemPage>
  );
}

export default function InHouseApp() {
  const initialState = createInitialState();
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useInHouseAppsService,
  });

  const { pageState, model } = pageProps;

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <InHouseAppComponent
      // reset component when isEditing changes
      key={`${model.id}:${pageState.isEditing}`}
      pageProps={pageProps}
    />
  );
}
