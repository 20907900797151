import { i18n } from 'i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class DiskManagementService extends NewLibraryItemService {
  static volumeTypes = {
    ALLOWED: 'Allowed',
    DISALLOWED: 'Disallowed',
    READONLY: 'ReadOnly',
  } as const;

  static volumeTypeOptions = () => [
    {
      label: i18n.t('Allowed'),
      value: this.volumeTypes.ALLOWED,
    },
    {
      label: i18n.t('Read Only'),
      value: this.volumeTypes.READONLY,
    },
    {
      label: i18n.t('Disallowed'),
      value: this.volumeTypes.DISALLOWED,
    },
  ];
}

export const diskManagementService = new DiskManagementService();

export default DiskManagementService;
