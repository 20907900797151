/* istanbul ignore file */
import {
  Flex,
  Icon,
  type IconNames,
  Link,
  Tabs,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';

import { formatNumber } from '@kandji-inc/nectar-i18n';
import { i18n } from 'i18n';
import { useEffect, useRef, useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { SeverityProgressChips } from '../../components/severity-progress-chips';
import { SeverityTooltip } from '../../components/severity-tooltip';
import { Timeline } from '../../components/timeline';
import type { TimelineItem } from '../../components/timeline/timeline';
import { translatedSeverity } from '../../constants';
import { displayAndTruncateList } from '../../helpers';
import { Severity, type Vulnerability } from '../../vulnerability.types';
import { ApplicationList } from './tabs/applications';
import { DeviceList } from './tabs/device-list';

const DetailRow = styled(Flex, {
  flexDirection: 'row',
  maxWidth: '88%',
});

const DetailKey = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  flex: '0 0 150px',
});

const DetailValue = styled(Flex, {
  flex: 1,
  maxWidth: 'calc(100% - 150px)',
});

const DetailText = styled(Text, {
  fontSize: '$1',
  fontWeight: '$medium',

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const tabsContainerCss = {
  '& > div[data-nectar-styled-name="group"]': {
    position: 'sticky',
    top: 0,
    bg: '$neutral0',
    zIndex: 2,
    marginInline: -20,
    paddingInline: 20,
    width: 'calc(100% + 40px)',
    '& div[data-nectar-styled-name="list"]': {
      position: 'relative',
    },
    '& > div[data-nectar-styled-name="separator"]': {
      width: 'calc(100% - 40px)',
    },
  },
  flex: 1,
};

const Body = (props: { selectedVulnerability: Vulnerability }) => {
  const { selectedVulnerability } = props;
  const {
    application_count,
    cve_modified_at,
    cve_published_at,
    description,
    device_count,
    cvss_score,
    kev_score,
    epss_score = 0,
    latest_detection_date,
    first_detection_date,
    severity,
  } = selectedVulnerability;

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);
  const [isViewingFullDescription, setIsViewingFullDescription] =
    useState(false);
  const descriptionTextRef = useRef<HTMLDivElement>(null);

  const recommendations = [
    i18n.t('Install new application update when available from the developer'),
    i18n.t('block the application using the Application Blocking Library Item'),
    i18n.t('uninstall the application using a custom script'),
  ];

  const recommendationsRef = useRef<HTMLDivElement>(null);
  const [visibleRecommendations, setVisibleRecommendations] = useState<
    string[]
  >([]);
  const [hiddenRecommendations, setHiddenRecommendations] = useState<string[]>(
    [],
  );
  const [isViewingFullRecommendations, setIsViewingFullRecommendations] =
    useState(false);

  const timelineItems: TimelineItem[] = [
    {
      label: i18n.t('Published on'),
      type: 'published',
      date: cve_published_at,
      theme: 'default',
      icon: 'file-plus',
    } as TimelineItem,
    {
      label: i18n.t('First detected'),
      type: 'first-detected',
      date: first_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    } as TimelineItem,
    ...(cve_modified_at
      ? [
          {
            label: i18n.t('Modified on'),
            type: 'modified',
            date: cve_modified_at,
            theme: 'default',
            icon: 'pen-field' as IconNames,
          } as TimelineItem,
        ]
      : []),
    {
      label: i18n.t('Last detected'),
      type: 'last-detected',
      date: latest_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    } as TimelineItem,
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Determines the overflow state of the Description
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (descriptionTextRef?.current) {
        setIsDescriptionOverflowing(
          descriptionTextRef.current.scrollWidth >
            descriptionTextRef.current.clientWidth,
        );
      }
    });

    observer.observe(descriptionTextRef?.current!);
    return () => observer.disconnect();
  }, []);

  // Determines the overflow state of the recommendations list
  useEffect(() => {
    const updateRecommendations = () => {
      if (recommendationsRef.current) {
        const maxWidth = recommendationsRef.current.clientWidth;
        const { displayed, hidden } = displayAndTruncateList(
          recommendations,
          maxWidth,
          ', ',
          recommendationsRef.current,
        );
        setVisibleRecommendations(displayed);
        setHiddenRecommendations(hidden);
      }
    };

    const observer = new ResizeObserver(updateRecommendations);
    if (recommendationsRef.current) {
      observer.observe(recommendationsRef.current);
    }

    updateRecommendations(); // Initial call
    return () => observer.disconnect();
  }, []);

  return (
    <Flex flow="column" gap="sm" pt2 flex={1} css={{ marginBottom: '80px' }}>
      {description && (
        <DetailRow>
          <DetailKey>{i18n.t('Description')}</DetailKey>
          <DetailValue
            css={isViewingFullDescription ? { display: 'inline' } : {}}
          >
            <DetailText
              ref={descriptionTextRef}
              css={{
                ...(isViewingFullDescription
                  ? {
                      display: 'inline',
                      overflow: 'visible',
                      whiteSpace: 'normal',
                    }
                  : {}),
              }}
            >
              {description}
            </DetailText>

            {isDescriptionOverflowing && !isViewingFullDescription && (
              <Link
                variant="primary"
                onClick={() => setIsViewingFullDescription(true)}
              >
                <DetailText>{i18n.t('see more')}</DetailText>
              </Link>
            )}

            {isViewingFullDescription && (
              <>
                <EDRCopyButton
                  value={description}
                  css={{
                    triggerCss: {
                      marginRight: '$1',
                      padding: '2px 0 0 2px',
                      display: 'inline-block',
                      height: 16,
                      width: 16,
                      svg: { width: 16, height: 16 },
                    },
                  }}
                />
                <Link
                  variant="primary"
                  onClick={() => setIsViewingFullDescription(false)}
                >
                  <DetailText css={{ display: 'inline' }}>
                    {i18n.t('see less')}
                  </DetailText>
                </Link>
              </>
            )}
          </DetailValue>
        </DetailRow>
      )}

      <DetailRow>
        <DetailKey>
          {i18n.t('Severity')} | {i18n.t('CVSS score')}
        </DetailKey>
        <DetailValue>
          {severity !== Severity.undefined && cvss_score !== null && (
            <SeverityTooltip severity={severity}>
              <Flex flow="row">
                <SeverityProgressChips severity={severity} />
                <DetailText>
                  {translatedSeverity[severity]()}
                  {cvss_score && ' | '}
                  {i18n.format.number(cvss_score) ?? ''}
                </DetailText>
              </Flex>
            </SeverityTooltip>
          )}
          <DetailText>
            {severity === Severity.undefined && !cvss_score && (
              <span style={{ fontStyle: 'italic' }}>{i18n.t('Pending')}</span>
            )}
          </DetailText>
        </DetailValue>
      </DetailRow>

      <DetailRow>
        <DetailKey>{i18n.t('Known exploit')}</DetailKey>
        <DetailValue>
          <DetailText>
            {kev_score === 1 ? i18n.t('Yes') : i18n.t('No')}
          </DetailText>
        </DetailValue>
      </DetailRow>

      <DetailRow>
        <DetailKey>
          <Flex alignItems="center" gap="xs">
            {i18n.t('EPSS')}
            <Tooltip
              content="Probability of being exploited in the next 30 days"
              side="bottom"
              theme="light"
              css={{ zIndex: 2 }}
            >
              <Flex>
                <Icon
                  name="circle-info"
                  size="xs"
                  color="var(--colors-neutral70)"
                />
              </Flex>
            </Tooltip>
          </Flex>
        </DetailKey>
        <DetailValue>
          <DetailText>
            {i18n.t('{epss}%', { epss: formatNumber(epss_score * 100) })}
          </DetailText>
        </DetailValue>
      </DetailRow>

      <DetailRow>
        <DetailKey>{i18n.t('Recommendations')}</DetailKey>
        <DetailValue
          ref={recommendationsRef}
          css={isViewingFullRecommendations ? { display: 'inline' } : {}}
        >
          <DetailText
            css={{
              ...(isViewingFullRecommendations
                ? {
                    display: 'inline',
                    whiteSpace: 'normal',
                  }
                : {}),
            }}
          >{`${(isViewingFullRecommendations ? recommendations : visibleRecommendations).join(', ')}`}</DetailText>

          {hiddenRecommendations?.length > 0 &&
            !isViewingFullRecommendations && (
              <Link
                variant="primary"
                css={{ paddingLeft: '$1' }}
                onClick={() => setIsViewingFullRecommendations(true)}
              >
                <DetailText>
                  {i18n.t('see {count} more', {
                    count: formatNumber(hiddenRecommendations.length),
                  })}
                </DetailText>
              </Link>
            )}

          {hiddenRecommendations?.length > 0 &&
            isViewingFullRecommendations && (
              <Link
                variant="primary"
                css={{ paddingLeft: '$1' }}
                onClick={() => setIsViewingFullRecommendations(false)}
              >
                <DetailText css={{ display: 'inline' }}>
                  {i18n.t('see less')}
                </DetailText>
              </Link>
            )}
        </DetailValue>
      </DetailRow>

      <Timeline
        items={timelineItems}
        showAge
        ageStartType="first-detected"
        ageEndType="last-detected"
      />

      <Flex flex={1}>
        <Tabs.Container
          compact
          tabs={[
            {
              label: (
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Devices')}{' '}
                  <span
                    style={{ fontWeight: '400' }}
                  >{`(${i18n.format.number(device_count)})`}</span>
                </Text>
              ),
              tabId: 'devices',
            },
            {
              label: (
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Applications')}{' '}
                  <span
                    style={{ fontWeight: '400' }}
                  >{`(${i18n.format.number(application_count)})`}</span>
                </Text>
              ),
              tabId: 'applications',
            },
          ]}
          defaultTabId="devices"
          css={tabsContainerCss}
        >
          <Tabs.Content tabId="devices">
            <DeviceList selectedVulnerability={selectedVulnerability} />
          </Tabs.Content>
          <Tabs.Content tabId="applications">
            <ApplicationList selectedVulnerability={selectedVulnerability} />
          </Tabs.Content>
        </Tabs.Container>
      </Flex>
    </Flex>
  );
};

export { Body };
