import { setClass } from '@kandji-inc/bumblebee';
import React from 'react';

import { DEVICE_FAMILY_ICONS_LIST } from './constants';
import { AppleTV, IPad, IPhone, Mac as MacIcon } from './icons';

import { Icon } from '@kandji-inc/nectar-ui';

const getDeviceFamilyIcon = (deviceFamily) => {
  switch (deviceFamily) {
    case 'Mac':
      return <MacIcon />;
    case 'iPhone':
      return <IPhone />;
    case 'iPad':
      return <IPad />;
    case 'Apple TV':
      return <AppleTV />;
    case 'Vision':
      return <Icon size="sm" name="sf-apple-vision-pro" />;
    default:
      return null;
  }
};

/**
 * Constructs a displayable list of icons that represent what devices an assignment rule is supporting and enabling
 * @param {Array} facetSupportedDeviceFamilies - a list of device families supported by the facet
 * @param {Array} libraryItemSupportedDeviceFamilies - a list of device families supported by the library item
 * @param {Array} installOnDeviceFamilies - a list of device families selected in the 'Install on' field on the library item page
 * @param {Boolean} optionIsDisabled - whether or not the entire rule or facet is disabled
 * @returns a displayable list of icons that represent what devices an assignment rule is supporting and enabling
 */
const getIcons = (
  facetSupportedDeviceFamilies = [],
  libraryItemSupportedDeviceFamilies,
  installOnDeviceFamilies,
  optionIsDisabled,
) =>
  DEVICE_FAMILY_ICONS_LIST.map((deviceFamily) => {
    const isSupportedByLibraryItem =
      libraryItemSupportedDeviceFamilies.includes(deviceFamily);
    const isSupportedByFacet =
      facetSupportedDeviceFamilies.includes(deviceFamily);

    if (isSupportedByLibraryItem && isSupportedByFacet) {
      // The icon should be disabled if the entire rule (option) is disabled or if the
      // icon's corresponding device has not been selected in the 'Install on' field
      const isDisabled =
        optionIsDisabled || !installOnDeviceFamilies.includes(deviceFamily);

      return (
        <span
          className={setClass(
            'b-mr-micro',
            isDisabled ? 'device-icon-disabled' : 'device-icon',
          )}
          key={deviceFamily}
          data-testid={deviceFamily}
        >
          {getDeviceFamilyIcon(deviceFamily)}
        </span>
      );
    }

    return null;
  });

export { getIcons, getDeviceFamilyIcon };
